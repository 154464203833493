import Vue from "vue"
import throttle from "lodash/throttle"

import store from "@/store"

import getInnerHeight from "@/lib/get-inner-height"
import isAppStore from "@/lib/is-platform/is-app-store"
import isBuilderContext from "@/lib/is-platform/is-builder-context"
import isStandalone from "@/lib/is-platform/is-standalone"

export const appHeight = Vue.observable({ height: 0, width: 0 })

const setAppHeight = (initial = false) => {
  const fullHeight = isAppStore() || isStandalone()
  appHeight.height =
    (fullHeight ? getInnerHeight() : window.innerHeight) -
    (isBuilderContext() ? 22 : 0) // Account for gap top

  appHeight.width = isBuilderContext() ? 375 : window.innerWidth

  document.documentElement.style.setProperty(
    "--app-height",
    `calc(${appHeight.height}px ${
      fullHeight ? "" : "+ env(safe-area-inset-top)"
    })`
  )

  if (initial === true) {
    // When the page is rendered, these are the values.
    // We use these values for Heros to prevent jankiness on scroll on iOS
    document.documentElement.style.setProperty(
      "--app-height-initial",
      `calc(${appHeight.height}px ${
        fullHeight ? "" : "+ env(safe-area-inset-top)"
      })`
    )
  }

  // TODO: Pass a callback and perform the commit there
  // Importing store is not ideal, and causes a large bundle
  store.commit("responsive/setResponsiveState", {
    height: appHeight.height,
    width: appHeight.width
  })
}

// https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
export default () => {
  window.addEventListener("resize", throttle(setAppHeight, 100))
  setAppHeight(true)
  setTimeout(() => setAppHeight(true), 1000)
  setTimeout(() => setAppHeight(false), 2000) // Pretty easy to scroll within the first 2 seconds and mess up the heights
}
