<template>
  <div class="v-image-input p-relative">
    <template v-if="imageUrl">
      <div class="v-image-preview w-100 d-flex">
        <div class="v-image-preview__image text-center p-1">
          <img :src="imageUrl" />
        </div>

        <div
          class="v-image-preview__footer d-flex justify-content-end align-items-center flex-grow-1 pl-1"
        >
          <span class="text-small font-weight-semi-bold text-grey-60">
            {{ imageName }}
          </span>
          <v-button
            small
            class="h-100 v-image-preview__remove ml-auto"
            secondary
            @click="removeImage"
          >
            {{ $t("components.forms.vImageInput.remove") }}
          </v-button>
        </div>
      </div>
    </template>
    <template v-else>
      <v-input
        :id="`image-upload-field-${uuid}`"
        class="v-image-input__field"
        type="file"
        data-cy="form-file-input"
        accept="image/*"
        :disabled="isUploading"
        @inputEvent="e => handleUpload(e)"
      />

      <label
        class="v-image-input__label d-flex p-2 pr-3 justify-content-center align-items-center w-100"
        :for="`image-upload-field-${uuid}`"
      >
        <transition name="fade" mode="out-in">
          <template v-if="isUploading">
            <div class="d-flex align-items-center">
              <v-loader />
              <span class="text-small font-weight-semi-bold text-grey-60 ml-1">
                {{ $t("components.forms.vImageInput.uploading") }}
              </span>
            </div>
          </template>
          <template v-else>
            <div
              class="d-flex justify-content-between align-items-center w-100"
            >
              <span class="text-grey-80 font-weight-semi-bold">
                {{ $t("components.forms.vImageInput.chooseImage") }}
              </span>
              <v-icon icon="upload" />
            </div>
          </template>
        </transition>
      </label>

      <div class="text-small text-red mt-1" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </template>
  </div>
</template>

<script>
import { isObject } from "@/lib/utils"
import ImageUpload from "@/lib/mixins/image-upload"
import vInput from "@/components/forms/vInput"

let uuid = 0

export default {
  mixins: [ImageUpload],

  components: {
    vInput
  },

  props: {
    value: { type: [Object, String], default: null }
  },

  data() {
    return {
      errorMessage: "",
      isUploading: false,
      imageUrl: isObject(this.value)
        ? this.value && this.value.sizes
          ? this.value.sizes.sm
          : ""
        : this.value,
      imageName: this.value && this.value.filename ? this.value.filename : ""
    }
  },

  beforeCreate() {
    // instead of using internal _uid
    // https://github.com/vuejs/vue/issues/5886#issuecomment-308647738

    this.uuid = uuid.toString()
    uuid++
  },

  methods: {
    handleUpload(e) {
      this.addFile(e.target.files[0])
    },

    async addFile(file) {
      this.reset()
      const validation = this.validateImage(file)

      if (!validation.valid) {
        this.showMessage(validation.errorMessage)
        return
      }

      this.isUploading = true

      try {
        const blob = await this.uploadImage(file)

        this.$emit("upload", blob)
        this.imageUrl = this.blobUrl(file)
        this.imageName = file.name
      } finally {
        this.isUploading = false
      }
    },

    reset() {
      this.imageUrl = ""
      this.imageName = ""
      this.errorMessage = ""
    },

    removeImage() {
      this.reset()
      this.$emit("remove")
      this.errorMessage = ""
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.v-image-preview {
  position: relative;
  display: inline-block;
  border: 2px solid $color-ui--grey-20;
  border-radius: var(--input-border-radius);
  overflow: hidden;

  img {
    width: 75px;
  }
}

.v-image-input__field {
  opacity: 0;

  input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
  }

  &:hover {
    & + .v-image-input__label {
      background: $color-ui--grey-10;
    }
  }
}

.v-image-input__label {
  background: $color-ui--white;
  border: 2px solid $color-ui--grey-20;
  border-radius: var(--input-border-radius);
  height: $size--10;
  transition: all 0.2s ease;

  &:hover {
    background: $color-ui--grey-10;
  }

  .v-loader {
    @include loader-color($color-ui--grey-60);
    height: 24px;
    width: 24px;
  }

  .v-icon {
    color: $color-ui--grey-50;
    transform: scale(1.6) translateY(1px);
  }
}

.v-image-preview__footer {
  overflow: hidden;
  flex-wrap: nowrap;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 66%;
  }
}

.v-image-preview__remove {
  min-width: 6rem;
}
</style>
