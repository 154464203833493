<template>
  <portal v-if="isVisible" selector="#cta-row-portal">
    <transition appear name="fade-in-up">
      <div
        v-if="show"
        :class="[
          {
            '--no-spacing': noSpacing,
            '--overlay': overlay,
            '--overlay-modal': overlayModal,
            'z-3': !overlayModal && !overlay
          },
          classes,
          'v-cta-row'
        ]"
        ref="ctaRow"
        v-bind="$attrs"
        :style="{ transform: `translateY(-${offsetBottom}px)` }"
      >
        <template v-if="$slots.float">
          <div
            :class="[
              { '--safe-padding': !$slots.default },
              'v-cta-row__float-wrapper d-flex align-items-center justify-content-center'
            ]"
          >
            <div
              :class="[{ 'w-100': fullWidthFloat }, 'v-cta-row__float-content']"
            >
              <slot name="float" />
            </div>
          </div>
        </template>

        <div
          :class="[
            {
              '--white-bg': whiteBg,
              '--overlay': overlay,
              '--empty': !$slots.default
            },
            'v-cta-row__content-wrapper'
          ]"
        >
          <div
            :class="[
              {
                '--empty': !$slots.default,
                '--no-spacing': noSpacing,
                '--row': row,
                '--align-center': alignCenter,
                '--align-start': alignStart
              },
              'v-cta-row__content container'
            ]"
          >
            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex"

import isBuilderContext from "@/lib/is-platform/is-builder-context"

export default {
  inheritAttrs: false,

  props: {
    ctaId: { type: String, default: null },
    noSpacing: { type: Boolean, default: false },
    whiteBg: { type: Boolean, default: false },
    row: { type: Boolean, default: false },
    forceShow: { type: Boolean, default: false },
    overlayModal: { type: Boolean, default: false },
    alignCenter: { type: Boolean, default: false },
    alignStart: { type: Boolean, default: false },
    overlay: { type: Boolean, default: false },
    show: { type: Boolean, default: true },
    fullWidthFloat: { type: Boolean, default: false },
    classes: { type: String, default: "" },
    observeHeight: { type: Boolean, default: false }
  },

  data() {
    return {
      initializedPath: null,
      heightObserver: null,
      timer: null
    }
  },

  computed: {
    ...mapState("videos", { isVideoFullscreen: "isFullscreen" }),
    ...mapState("responsive", ["isDesktop"]),
    ...mapGetters("tabbar", ["isTabbarShown"]),

    isVisible() {
      return (
        this.forceShow ||
        (!this.isVideoFullscreen &&
          (this.initializedPath === this.$route.path || isBuilderContext()))
      )
    },

    offsetBottom() {
      return isBuilderContext()
        ? 0
        : !this.isDesktop && !this.overlayModal && this.isTabbarShown
        ? 53
        : 0
    }
  },

  methods: {
    ...mapMutations("ui", ["setCtaHeight", "clearCtaHeight"]),

    initObserver(counter = 1) {
      if (this.observeHeight && this.ctaId) {
        this.timer = setTimeout(() => {
          if (this.$refs["ctaRow"]) {
            this.initResizeObserver()
          } else if (counter < 10) {
            this.initObserver(counter + 1)
          }
        }, 100)
      }
    },

    initResizeObserver() {
      this.heightObserver = new ResizeObserver(entries => {
        const entry = entries.find(e => e.target === this.$refs["ctaRow"])

        // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentRect
        const height =
          (entry?.borderBoxSize && entry.borderBoxSize[0]
            ? entry.borderBoxSize[0].blockSize
            : undefined) ||
          entry?.contentRect?.height ||
          this.$refs["ctaRow"].offsetHeight

        this.setCtaHeight({
          id: this.ctaId,
          height: height + this.offsetBottom
        })
      })

      this.heightObserver.observe(this.$refs["ctaRow"])
    }
  },

  mounted() {
    this.initializedPath = this.$route.path

    if (this.observeHeight) {
      this.initObserver()
    }
  },

  beforeDestroy() {
    if (this.observeHeight && this.ctaId) {
      this.$refs["ctaRow"] &&
        this.heightObserver?.unobserve(this.$refs["ctaRow"])
      this.clearCtaHeight(this.ctaId)

      clearTimeout(this.timer)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

$cta-row--offset-bottom: 57px;

.v-cta-row {
  left: 0px;
  bottom: 0;
  pointer-events: none;
  width: 100%;
  transition: transform 0.2s ease;
  position: fixed;

  & > * {
    pointer-events: auto;
  }

  &.--overlay-modal {
    z-index: $overlay-modal--z-index;
  }

  &.--overlay {
    z-index: 21;
    animation: fade-in-up 0.36s ease;
  }

  &.--no-spacing {
    .v-cta-row__content,
    .v-cta-row__content-wrapper {
      padding: 0 !important;
    }
  }
}

.v-cta-row__content-wrapper {
  padding-bottom: env(safe-area-inset-bottom);

  &.--white-bg,
  &.--overlay {
    @include tabbar-background;

    backface-visibility: hidden;
    perspective: 10;
    transform: translate3d(0, 0, 0);
    box-shadow: $box-shadow--large;
    border-top: 1px solid $color-ui--grey-20;
  }

  &.--overlay {
    border-top-left-radius: var(--input-border-radius);
    border-top-right-radius: var(--input-border-radius);
    border-top: none;
  }

  &.--empty {
    padding: 0;
    opacity: 0;

    .v-cta-row__content {
      padding: 0;
    }
  }
}

.v-cta-row__content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  padding: $size--2;

  .v-button {
    flex-grow: 1;
  }

  &.--row {
    align-items: center;
    flex-direction: row;

    .cta-row-price {
      min-width: 34%;
    }
  }

  &.--align-center {
    align-items: center;
  }

  &.--align-start {
    align-items: flex-start;
  }
}

.v-cta-row__float-wrapper {
  pointer-events: none;

  .v-cta-row__float-content {
    pointer-events: auto;
  }

  &.--safe-padding {
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.global-player__cta-wrapper {
  .v-cta-row__content-wrapper {
    padding-bottom: 0;
    margin-bottom: env(safe-area-inset-bottom);
  }
}

@media screen and (min-width: $breakpoint--xl) {
  .v-cta-row__content-wrapper {
    padding-left: $tabbar--desktop-width;
  }

  .v-cta-row.--overlay-modal {
    .v-cta-row__content-wrapper {
      padding-left: 0;
    }
  }
}
</style>
