<template>
  <div
    :class="['v-radio__wrapper', { '--disabled': disabled }]"
    @click="handleChange(value)"
  >
    <div class="v-radio">
      <input
        :class="[{ '--checked': isChecked }, 'v-radio__control']"
        tabindex="0"
        type="radio"
        :value="value"
        :checked="isChecked"
        @change="$emit('change', $event.target.value)"
      />
      <div class="v-radio__background">
        <div class="v-radio__outer-circle"></div>
        <div class="v-radio__inner-circle"></div>
      </div>
    </div>
    <div
      class="v-radio__label-wrapper d-flex flex-column justify-content-center"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "currentValue",
    event: "change"
  },

  props: {
    value: { type: [String, Boolean, Number], default: "" },
    currentValue: { type: [String, Boolean, Number], default: "" },
    allowMultiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },

  methods: {
    handleChange(value) {
      if (this.disabled) return

      if (this.allowMultiple) {
        const current = this.currentValue
          .split("|")
          .filter(v => v != null && v != "")

        const index = current.indexOf(value)
        if (index > -1) current.splice(index, 1)
        else current.push(value)

        this.$emit("change", current.join("|"))
      } else {
        this.$emit("change", value)
      }
    }
  },

  computed: {
    isChecked() {
      return this.allowMultiple
        ? this.currentValue.split("|").includes(this.value)
        : this.currentValue == this.value
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.v-radio__wrapper {
  display: flex;
  align-self: flex-start;

  &:not(.--disabled):hover {
    cursor: pointer;

    .v-radio {
      background: rgba(black, 0.1);
    }
  }

  &.--disabled {
    user-select: none;
    pointer-events: none;
    opacity: 0.6;

    .v-radio__outer-circle {
      border-color: $color-ui--grey-20;
    }
  }
}

.v-radio__control {
  position: absolute;
  opacity: 0;
}

.v-radio {
  top: 0;
  right: 0;
  left: 0;
  width: 40px;
  height: 40px;
  z-index: 2;
  border-radius: 50%;
  padding: 10px;
  background: rgba(black, 0);
  transition: background 0.2s ease;
  margin-right: 8px;
}

.v-radio__control {
  &.--checked {
    + .v-radio__background {
      .v-radio__outer-circle,
      .v-radio__inner-circle {
        border-color: var(--primary-brand-color-hex);
      }

      .v-radio__inner-circle {
        transform: scale(0.5);
      }
    }
  }
}

.v-radio__background {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
}

.v-radio__outer-circle,
.v-radio__inner-circle {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  border-color: grey;
}

.v-radio__inner-circle {
  background: var(--primary-brand-color-hex);
  transition: transform 0.2s ease;
  transform: scale(0);
}
</style>

<style lang="scss">
.v-radio__label-wrapper {
  label {
    margin-bottom: 0;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
