import { BlurHashCanvas } from "vue-blurhash"

import { isPresent } from "@/lib/utils"

export const shouldShow = image => isPresent(image) && image.blurhash

export const calcSize = containingElement => {
  if (!containingElement) return { width: 250, height: 250 }
  const { width, height } = containingElement.getBoundingClientRect()
  return { width: parseInt(width), height: parseInt(height) }
}

export { BlurHashCanvas }
