import "@/lib/audio/types"

import Plyr from "plyr"
import Emitter from "tiny-emitter"

import { PLAYER_EVENTS, ERRORS } from "@/lib/audio/shared"

import { configureHls, isHlsSupported } from "@/lib/hls-utils"

export default class WebPlayer {
  constructor(audioPlayer) {
    this.audioPlayer = audioPlayer

    this.emitter = new Emitter()
    this.hls = null

    this.element = this._createAudioElement()
    this.plyr = new Plyr(this.element, { controls: [] })

    // Need to keep our own state, as plyrs doesn't seem to be reactive
    this.buffered = 0
    this.currentTime = 0

    this._setupEventListeners()
  }

  get muted() {
    return this.plyr.muted
  }

  play() {
    this.plyr.play()
  }

  pause() {
    this.plyr.pause()
  }

  rewind(seconds) {
    this.plyr.rewind(seconds)
  }

  forward(seconds) {
    this.plyr.forward(seconds)
  }

  seek(seconds) {
    this.currentTime = seconds
    this.plyr.currentTime = seconds
  }

  /**
   * Sets the audio source for the player.
   * @param {AudioSource} source - The source object containing audio and page information.
   */
  setSource(source) {
    if (this.hls) this.hls.destroy()

    if (isHlsSupported()) {
      this._setupHls(source)
    } else if (this.element.canPlayType("application/vnd.apple.mpegurl")) {
      // Some browsers, like iOS Safari, support HLS Natively
      // https://docs.mux.com/guides/video/play-your-videos#3-use-the-hls-url-in-a-player
      this.element.src = source.audio.url
    } else {
      // TODO: Figure out fallback here?
      // Not sure when this would ever happen
    }
  }

  setMuted(isMuted) {
    this.plyr.muted = isMuted
  }

  setPlaybackSpeed(speed) {
    this.plyr.speed = speed
  }

  setLoop(loop) {
    this.plyr.loop = loop
  }

  on(event, callback) {
    this.emitter.on(event, callback)
  }

  destroy() {
    this.emitter.off()
    if (this.hls) this.hls.destroy()

    this.plyr.destroy()
  }

  _createAudioElement() {
    const element = document.createElement("audio")

    element.setAttribute("preload", "auto")
    element.setAttribute("controls", "")
    element.setAttribute("crossorigin", "")
    element.setAttribute("playsinline", "")

    element.classList.add("d-none")
    document.body.appendChild(element)

    return element
  }

  _setupHls(source) {
    this.hls = configureHls(source.audio.url, this.element, () => {
      // Reset the current source
      this.setSource(source)

      // Emit an error
      this.emitter.emit(PLAYER_EVENTS.ERROR, {
        detail: { name: ERRORS.HLS_ERROR }
      })
    })
  }

  _setupEventListeners() {
    this.plyr.on("error", e => {
      this.emitter.emit(PLAYER_EVENTS.ERROR, e)
    })

    this.plyr.on("play", () => {
      this.emitter.emit(PLAYER_EVENTS.PLAY)
    })

    this.plyr.on("ended", () => {
      this.emitter.emit(PLAYER_EVENTS.ENDED)
    })

    this.plyr.on("timeupdate", () => {
      this.emitter.emit(PLAYER_EVENTS.TIME_UPDATE)

      this.currentTime = this.plyr.currentTime
      this.buffered = this.plyr.buffered
    })

    this.plyr.on("waiting", () => {
      this.emitter.emit(PLAYER_EVENTS.WAITING)

      this.buffered = this.plyr.buffered
    })

    this.plyr.on("canplay", () => {
      this.emitter.emit(PLAYER_EVENTS.CAN_PLAY)

      this.buffered = this.plyr.buffered
    })

    this.plyr.on("progress", () => {
      this.emitter.emit(PLAYER_EVENTS.PROGRESS)

      this.buffered = this.plyr.buffered
    })

    this.plyr.on("ready", () => {
      this.emitter.emit(PLAYER_EVENTS.READY)
    })
  }
}
