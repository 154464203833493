import i18n from "@/store/i18n"
import { ProductInterval } from "@shared/types"

export const productInterval = ({
  interval,
  intervalCount = 1,
  expiryUnits,
  withPrefix = true
}: {
  interval: ProductInterval
  intervalCount: number
  expiryUnits?: number | null
  withPrefix?: boolean
}): string => {
  const prefix: string = withPrefix
    ? (intervalCount && intervalCount > 1
        ? i18n.t("lib.productInterval.every")
        : i18n.t("lib.productInterval.per")
      ).toString()
    : ""

  const i18nKey: string = interval ? `lib.productInterval.${interval}s` : ""

  const productInterval: string = intervalCount
    ? i18n.tc(i18nKey, intervalCount)
    : ""

  const expiryInterval: string =
    expiryUnits && expiryUnits > 0
      ? ` ${i18n.t("lib.productInterval.for")} ${i18n.tc(i18nKey, expiryUnits, {
          singleCount: expiryUnits === 1 ? 1 : ""
        })}`
      : ""

  return `${prefix} ${productInterval}${expiryInterval}`
}
