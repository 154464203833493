import { autoLink } from "vue-highlights"
import { MESSAGE_TYPES } from "@/lib/pusher-utils"
import { isPresent } from "@/lib/utils"
import { urlCompare } from "@/lib/url-helpers"

const ONE_MINUTE = 60000

export const QUERY_PARAMS = {
  SHOW_THREAD: "show_thread"
}

export const THREAD_MODAL_HASH = {
  PARTICIPANTS: "#participants",
  RENAMING: "#renaming",
  PROFILE: "#profile",
  SUBSCRIBERS: "#members"
}

export const isGroupedType = message =>
  message.message_type === MESSAGE_TYPES.GROUPED

export const isTextType = message =>
  message.message_type === MESSAGE_TYPES.MESSAGE

export const isImageType = message =>
  message.message_type === MESSAGE_TYPES.IMAGE

export const isVideoType = message =>
  message.message_type === MESSAGE_TYPES.VIDEO

export const isAttachmentType = message =>
  message.message_type === MESSAGE_TYPES.ATTACHMENT

export const isMediaType = message =>
  isImageType(message) || isVideoType(message) || isAttachmentType(message)

export const isNotificationType = message =>
  message.message_type === MESSAGE_TYPES.NOTIFICATION

export const isMessageType = message =>
  [
    MESSAGE_TYPES.MESSAGE,
    MESSAGE_TYPES.IMAGE,
    MESSAGE_TYPES.VIDEO,
    MESSAGE_TYPES.ATTACHMENT
  ].includes(message.message_type)

export const isReactionType = message =>
  [message.message_type, message.messageType].includes(MESSAGE_TYPES.REACTION)

export const isSameUser = (message1, message2) => {
  return (
    isMessageType(message1) &&
    isMessageType(message2) &&
    message1.user.id === message2.user.id
  )
}

export const isSelf = (message, user) => {
  return (
    (isMessageType(message) || isGroupedType(message)) &&
    message.user?.id === user.id
  )
}

export const isSameAsPrevious = (messages, index, message) =>
  index > 0 && isSameUser(message, messages[index - 1])

export const isLastFromUser = (messages, index, message) =>
  isMessageType(message) &&
  (!messages[index + 1] || !isSameUser(message, messages[index + 1]))

export const isLatestInThread = (messages, i, message) =>
  isMessageType(message) && !messages[i + 1]

export const isFirstFromUser = (messages, index, message) =>
  isMessageType(message) &&
  (!messages[index - 1] || !isSameUser(message, messages[index - 1]))

export const buildMessageId = message =>
  `${message.message_thread_id}-${message.message_ts}`

export const parseMessageId = messageId => messageId.split("-")

export const MESSAGE_ORDER = {
  LATEST_FIRST: "desc",
  OLDEST_FIRST: "asc"
}

export const MENTION_SYMBOL = "@"
export const MENTION_OPENER = "<"
export const MENTION_DELIMITER = ">"

// Must match /backend/app/models/message_thread_message.rb
export const MENTION_REGEX = /@<(?:(?!@<).)+?>/g

const encodeHtmlEntities = text =>
  text
    .replace(/</g, "&lt;") // Ensure mentions are preserved
    .replace(/>/g, "&gt;")

const decodeHtmlEntities = text =>
  text
    .replace(/&lt;/g, "<") // Ensure mentions are preserved
    .replace(/&gt;/g, ">")

const standardizedLinks = text =>
  process.env.NODE_ENV === "development"
    ? text.replace("localhost", "localhost.dev")
    : text

export const generatedMessagesFromLinkText = ({
  text,
  createdAt = +new Date()
}) => {
  const messages = []
  const beforeAttachments = []
  const afterAttachments = []

  const htmlContent = new DOMParser().parseFromString(
    autoLink(encodeHtmlEntities(standardizedLinks(text))),
    "text/html"
  ).body

  // Remove \n \t,... between urls
  htmlContent.childNodes.forEach(node => {
    if (!node.textContent.trim()) htmlContent.removeChild(node)
  })

  // Filter attachments before text message
  while (
    htmlContent.firstChild &&
    htmlContent.firstChild.nodeName === "A" &&
    !htmlContent.firstChild.textContent.includes("@")
  ) {
    beforeAttachments.push(htmlContent.firstChild)
    htmlContent.removeChild(htmlContent.firstChild)
  }

  // Filter attachments after text message
  while (
    htmlContent.lastChild &&
    htmlContent.lastChild.nodeName === "A" &&
    !htmlContent.lastChild.textContent.includes("@")
  ) {
    afterAttachments.unshift(htmlContent.lastChild)
    htmlContent.removeChild(htmlContent.lastChild)
  }

  beforeAttachments.forEach(attachment => {
    messages.push(
      buildAttachmentMessageForSubmission({
        attachment: attachment,
        createdAt: createdAt + messages.length
      })
    )
  })

  if (htmlContent.textContent.trim()) {
    messages.push({
      messageBody: decodeHtmlEntities(htmlContent.textContent?.trim()),
      messageType: MESSAGE_TYPES.MESSAGE,
      messageCreatedAt: createdAt + messages.length
    })
  }

  afterAttachments.forEach(attachment => {
    messages.push(
      buildAttachmentMessageForSubmission({
        attachment: attachment,
        createdAt: createdAt + messages.length
      })
    )
  })
  return messages
}

export const extractUrls = (text, includeDuplicates = false) => {
  const htmlContent = new DOMParser().parseFromString(text, "text/html").body

  const urls = htmlContent
    ? Array.from(htmlContent.querySelectorAll("a[href]")).map(node => node.href)
    : []

  return includeDuplicates
    ? urls
    : // Filter out duplicate links
      urls.reduce((acc, url) => {
        const existingIndex = acc.findIndex(u => urlCompare(u, url))

        if (existingIndex === -1) {
          acc.push(url)
        } else if (url.startsWith("https")) {
          acc[existingIndex] = url
        }

        return acc
      }, [])
}

const buildAttachmentMessageForSubmission = ({ attachment, createdAt }) => {
  return {
    attachmentData: {
      fetching: true,
      url: attachment.href
    },
    messageType: MESSAGE_TYPES.ATTACHMENT,
    messageCreatedAt: createdAt
  }
}

export const buildMessage = ({
  messageThreadId,
  user,
  body,
  imageUrls,
  videoUrl,
  attachmentData,
  messageTs,
  messageType,
  replyThreadId,
  reactionCount = 0,
  messages = [],
  isRoot = false
}) => ({
  user,
  message: body,
  image_urls: imageUrls,
  video_url: videoUrl,
  attachment_data: attachmentData,
  created_at: messageTs,
  message_ts: messageTs,
  message_type: messageType,
  message_thread_id: messageThreadId,
  reply_thread_id: replyThreadId,
  reaction_count: reactionCount,
  messages: messages,
  is_root: isRoot
})

export const buildMessageFromPusherData = (messageThreadId, pusherData) => {
  const buildMessages = isPresent(pusherData.messages)
    ? pusherData.messages.map(message =>
        buildMessageFromPusherData(messageThreadId, message)
      )
    : []

  return buildMessage({
    messageThreadId,
    user: pusherData.user,
    body: pusherData.messageBody,
    imageUrls: pusherData.imageUrls,
    videoUrl: pusherData.videoUrl,
    attachmentData: pusherData.attachmentData,
    messageTs: pusherData.messageTs || pusherData.messageCreatedAt,
    messageType: pusherData.messageType,
    replyThreadId: pusherData.replyThreadId,
    reactionCount: pusherData.reactionCount,
    messages: buildMessages,
    isRoot: pusherData.isRoot || false
  })
}

export const groupMessages = messages => {
  const sortedMessages = messages
    .filter(message => !!message.is_root)
    .sort((a, b) => a.message_ts - b.message_ts)

  const groupedMessages = sortedMessages.reduce((acc, message, index) => {
    if (
      !isGroupedType(message) &&
      isSameAsPrevious(sortedMessages, index, message) &&
      (acc["last_ts"]
        ? acc["last_ts"] + ONE_MINUTE > parseInt(message.message_ts)
        : true)
    ) {
      acc[acc["last_ts"]].push(message)
    } else {
      acc[message.message_ts] = [message]
      acc["last_ts"] = parseInt(message.message_ts)
    }

    return acc
  }, {})

  delete groupedMessages["last_ts"]

  return groupedMessages
}

export const isVisibleMessage = (message, blockedUserIds) =>
  message.user && !blockedUserIds.includes(message.user.id)

export const visibleMessages = (messages, blockedUserIds = []) => {
  return isPresent(blockedUserIds)
    ? messages.filter(message => isVisibleMessage(message, blockedUserIds))
    : messages
}

export const shownParticipants = (messageThread, currentUser = null) => {
  if (!messageThread) return []

  let participants = messageThread.participants || []

  if (currentUser) {
    participants = participants.filter(
      participant => participant.user && participant.user.id !== currentUser.id
    )
  }

  return participants.filter(participant => Boolean(participant.user))
}

export const sortMessagesAsPosts = messages => {
  const sortedMessageArrays = Object.values(messages).sort((a, b) => {
    // Compare the first messages in each array
    return messageCompare(a[a.length - 1], b[b.length - 1])
  })

  return sortedMessageArrays
}

const messageCompare = (a, b) => {
  if (a.pinned_at && b.pinned_at) {
    return a.pinned_at - b.pinned_at
  }

  if (a.pinned_at) {
    return -1
  }

  if (b.pinned_at) {
    return 1
  }

  return b.message_ts - a.message_ts
}

export const formatMentions = body => {
  return body.replace(MENTION_REGEX, mention => {
    const name = mention
      .replace(MENTION_OPENER, "")
      .replace(MENTION_DELIMITER, "")

    return `<span class="mention">${name}</span>`
  })
}

export const canModerateMessage = (message, user, adminOnly = false) =>
  isPresent(user) && isPresent(message) && adminOnly
    ? user.isAccountAdmin
    : user.isAccountAdmin || user.id === message.user.id

export const canReportOrBlockMessage = (message, user) =>
  isPresent(user) && isPresent(message) && user.id !== message.user.id

export const canStartMessage = (subscriber, store) => {
  const { getters } = store

  if (
    !isPresent(subscriber) ||
    !getters["isChatAvailable"] ||
    !isPresent(getters["auth/currentUser"]) ||
    getters["auth/currentUser"].id == subscriber.user_id
  ) {
    // Block messages on free app and myself
    return false
  }

  if (getters["auth/canMessageAll"]) return true
  if (!subscriber.can_receive_messages_from_members) return false

  const appSubscriberIds = (
    store.state.messages.appMessagingProfiles || []
  ).map(p => p.id)

  // If member to member is disabled -> block messages to members
  // If member to admin is disabled -> block messages to admins
  return (
    (getters["memberToMemberMessagingEnabled"] &&
      !appSubscriberIds.includes(subscriber.id)) ||
    (getters["memberToAdminMessagingEnabled"] &&
      appSubscriberIds.includes(subscriber.id))
  )
}
