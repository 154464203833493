import pusherClient from "@/lib/pusher-client"

import { v4 as uuid } from "uuid"
import { isEmpty } from "@/lib/utils"

import pusherEvents from "@shared/pusher-events.json"

export const CHANNEL_EVENTS = pusherEvents

export const MESSAGE_TYPES = {
  MESSAGE: "message",
  IMAGE: "image",
  VIDEO: "video",
  ATTACHMENT: "attachment",
  GROUPED: "grouped",
  MESSAGE_REACTION: "message-reaction",
  MESSAGE_DELETED: "message-deleted",
  MESSAGE_UPDATED: "message-updated",
  NOTIFICATION: "notification",
  REACTION: "reaction",
  MEMBER_BLOCKED: "member-blocked"
}

export const REACTION_TYPES = {
  LIKE: "like",
  UNLIKE: "unlike"
}

const wrapCallback = callback => val => (callback ? callback(val) : null)

const bindEvent = (channel, instance, event, callback) => {
  channel.bind(event, callback)
  channel._instanceCallbacks = channel._instanceCallbacks || {}
  channel._instanceCallbacks[instance] =
    channel._instanceCallbacks[instance] || {}
  channel._instanceCallbacks[instance][event] = callback
}

const teardownChannelInstance = (channel, instance) => {
  const callbacks = channel._instanceCallbacks[instance]

  // Remove all callbacks defined on this instance
  Object.keys(callbacks).forEach(eventName => {
    channel.unbind(eventName, callbacks[eventName])
    delete channel._instanceCallbacks[eventName]
  })

  // If there are no other listeners on the channel, we can unsubscribe completely
  if (isEmpty(channel.callbacks._callbacks)) {
    pusherClient.unsubscribe(channel.name)
  }
}

export const subscribeToThread = (
  channelId,
  callbacks = {
    onMessage: () => {},
    onMessageDeleted: () => {},
    onMessageUpdated: () => {},
    onMessageReaction: () => {},
    onThreadUpdated: () => {},
    onSubscribe: () => {},
    onMemberAdded: () => {}, // Member actively viewing
    onMemberRemoved: () => {}, // Member stopped viewing
    onMemberBlocked: () => {}, // Member blocked by another one
    onFormSubmitted: () => {},
    onParticipantAdded: () => {}, // Member joined thread
    onParticipantRemoved: () => {} // Member left thread
  },
  presence = true
) => {
  const instance = uuid()
  const [channel, presenceChannel] = pusherClient.subscribe(
    { channelId },
    presence
  )

  bindEvent(
    channel,
    instance,
    CHANNEL_EVENTS.MESSAGE,
    wrapCallback(callbacks.onMessage)
  )

  bindEvent(
    channel,
    instance,
    CHANNEL_EVENTS.MESSAGE_REACTION,
    wrapCallback(callbacks.onMessageReaction)
  )

  bindEvent(
    channel,
    instance,
    CHANNEL_EVENTS.MESSAGE_UPDATED,
    wrapCallback(callbacks.onMessageUpdated)
  )

  bindEvent(
    channel,
    instance,
    CHANNEL_EVENTS.MESSAGE_DELETED,
    wrapCallback(callbacks.onMessageDeleted)
  )

  bindEvent(
    channel,
    instance,
    CHANNEL_EVENTS.PARTICIPANT_REMOVED,
    wrapCallback(callbacks.onParticipantRemoved)
  )

  bindEvent(
    channel,
    instance,
    CHANNEL_EVENTS.THREAD_UPDATED,
    wrapCallback(callbacks.onThreadUpdated)
  )

  bindEvent(
    channel,
    instance,
    CHANNEL_EVENTS.FORM_SUBMITTED,
    wrapCallback(callbacks.onFormSubmitted)
  )

  bindEvent(
    channel,
    instance,
    CHANNEL_EVENTS.PARTICIPANT_ADDED,
    wrapCallback(callbacks.onParticipantAdded)
  )

  bindEvent(
    channel,
    instance,
    CHANNEL_EVENTS.MEMBER_BLOCKED,
    wrapCallback(callbacks.onMemberBlocked)
  )

  if (presenceChannel) {
    bindEvent(
      presenceChannel,
      instance,
      CHANNEL_EVENTS.SUBSCRIPTION_SUCCEEDED,
      wrapCallback(callbacks.onSubscribe)
    )

    bindEvent(
      presenceChannel,
      instance,
      CHANNEL_EVENTS.MEMBER_ADDED,
      wrapCallback(callbacks.onMemberAdded)
    )

    bindEvent(
      presenceChannel,
      instance,
      CHANNEL_EVENTS.MEMBER_REMOVED,
      wrapCallback(callbacks.onMemberRemoved)
    )
  }

  return { instance, channel, presenceChannel }
}

export const unsubscribeFromThread = thread => {
  const { channel, presenceChannel, instance } = thread

  if (channel) teardownChannelInstance(channel, instance)
  if (presenceChannel) teardownChannelInstance(presenceChannel, instance)
}

export const publishToThread = ({
  channel,
  messageBody,
  messageCreatedAt,
  user,
  memberIds,
  messageThreadId,
  imageIds = [],
  imageUrls = [],
  videoUrl,
  attachmentData = {},
  messageType = MESSAGE_TYPES.MESSAGE,
  messages = [],
  isRoot = false,
  sessionId = null,
  mentionedMembers = []
}) => {
  let messageData = {
    messageType,
    messageBody,
    messageCreatedAt,
    messageTs: messageCreatedAt,
    memberIds,
    messageThreadId,
    messages,
    isRoot,
    sessionId,
    user: {
      id: user.id,
      name: user.name,
      email: user.email,
      avatar_urls: user.avatarUrls
    },
    mentionedMembers
  }

  if (messageType === MESSAGE_TYPES.IMAGE) {
    messageData = {
      ...messageData,
      ...{ imageIds: imageIds, imageUrls: imageUrls }
    }
  } else if (messageType === MESSAGE_TYPES.VIDEO) {
    messageData = {
      ...messageData,
      ...{ videoUrl: videoUrl }
    }
  } else if (messageType === MESSAGE_TYPES.ATTACHMENT) {
    messageData = {
      ...messageData,
      ...{ attachmentData: attachmentData }
    }
  }

  channel.trigger(CHANNEL_EVENTS.MESSAGE, messageData)
}

export const removeFromThread = ({
  channel,
  user,
  messageTs,
  messageType = MESSAGE_TYPES.MESSAGE_DELETED,
  sessionId = null
}) => {
  channel.trigger(CHANNEL_EVENTS.MESSAGE_DELETED, {
    messageTs: messageTs,
    messageType,
    user,
    sessionId
  })
}

export const reactMessage = ({
  channel,
  user,
  action,
  likedUserId,
  messageTs,
  messageType = MESSAGE_TYPES.MESSAGE_REACTION,
  sessionId = null
}) => {
  channel.trigger(CHANNEL_EVENTS.MESSAGE_REACTION, {
    action,
    messageTs: messageTs,
    messageType,
    user,
    likedUserId,
    sessionId
  })
}
