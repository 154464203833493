import Axios from "axios"
import joinUrl from "url-join"

import { cachePage, removeCachedPage } from "@/lib/page-cache"
import isBuilderContext from "@/lib/is-platform/is-builder-context"

export default path => {
  if (isBuilderContext()) return Promise.resolve()
  if (path.startsWith("/me/")) return Promise.resolve()

  const url = joinUrl(
    process.env.VUE_APP_APP_DATA_PATH,
    path === "/" ? "" : path,
    "index.json",
    `?ts=${new Date().getTime()}`
  )

  return Axios.get(url)
    .then(res => {
      cachePage(path, res.data)

      return res.data
    })
    .catch(err => {
      if (err.response && err.response.status === 404) {
        removeCachedPage(path)
      }

      throw err
    })
}
