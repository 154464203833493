import { DirectUpload } from "@rails/activestorage"
import store from "@/store"
import { IMAGE_SIZE_LIMIT } from "@/lib/upload-helpers"
import i18n from "@/store/i18n"

export default {
  data() {
    return {
      errorMessage: "",
      isSaving: false
    }
  },

  methods: {
    directUploadWillCreateBlobWithXHR(xhr) {
      xhr.setRequestHeader("x-account-app", store.getters.app.slug)
      xhr.setRequestHeader("x-storage-type", "public")
    },

    validateImage(file, limitSize = true) {
      let valid = false
      let errorMessage = ""

      if (!file) {
        errorMessage = i18n.t("lib.mixins.imageUpload.fileIsEmpty")
      } else if (!file.type || !file.type.startsWith("image/")) {
        errorMessage = i18n.t("lib.mixins.imageUpload.invalidImageType")
      } else if (!file.size || (limitSize && file.size > IMAGE_SIZE_LIMIT)) {
        errorMessage = i18n.t("lib.mixins.imageUpload.imageTooLarge", {
          size: "15mb"
        })
      } else {
        valid = true
      }

      return {
        valid: valid,
        errorMessage: errorMessage
      }
    },

    uploadImage(file) {
      return new Promise((resolve, reject) => {
        this.isSaving = true

        const uploader = new DirectUpload(
          file,
          process.env.VUE_APP_API_BASE_URL + "/api/direct-upload",
          this
        )

        uploader.create((error, blob) => {
          if (error) {
            this.showMessage(i18n.t("lib.mixins.imageUpload.couldNotUpload"))
            this.isSaving = false

            return reject()
          }

          return resolve(blob)
        })
      })
    },

    blobUrl(file) {
      // https://stackoverflow.com/questions/49106045/preview-an-image-before-it-is-uploaded-vuejs
      const url = URL.createObjectURL(file)
      URL.revokeObjectURL(file)

      return url
    },

    showMessage(message) {
      this.errorMessage = message
    }
  }
}
