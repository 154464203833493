import scssVariables from "@/assets/styles/variables.module.scss"

const toInt = value => parseInt(value.replace("px", ""))

export default {
  namespaced: true,

  state: {
    isMobile: false,
    isTablet: false,
    isDesktop: false
  },

  mutations: {
    setResponsiveState(state, { width }) {
      state.isMobile = width <= toInt(scssVariables["breakpoint--md"])
      state.isTablet =
        !state.isMobile && width <= toInt(scssVariables["breakpoint--xl"])
      state.isDesktop = width >= toInt(scssVariables["breakpoint--xl"])
    }
  }
}
