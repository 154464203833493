import Vue from "vue"
import ApiClient from "@/lib/api-client"

export default {
  namespaced: true,

  state: {
    subscriptions: {}
  },

  actions: {
    subscribe({ commit }, eventOccurrenceId) {
      return ApiClient.post(
        `/event_occurrences/${eventOccurrenceId}/subscribe`
      ).then(() => commit("addEventSubscription", { eventOccurrenceId }))
    },

    fetchSubscriptions({ commit }) {
      return ApiClient.get("/event_occurrences/subscriptions").then(res => {
        commit("setEventSubscriptions", res.data.event_subscriptions)
      })
    },

    getEventOccurrenceAccess(_, eventOccurrenceId) {
      return ApiClient.get(`/event_occurrences/${eventOccurrenceId}/access`)
    },

    fetchEventsForPeriod(_, { start, end, eventId }) {
      return ApiClient.get("/event_occurrences/per_period", {
        params: { start, end, event_id: eventId }
      })
    }
  },

  mutations: {
    setEventSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions
    },

    addEventSubscription(state, { eventOccurrenceId }) {
      Vue.set(state.subscriptions, eventOccurrenceId, true)
    }
  },

  getters: {
    isUserSubscribedToEventOccurrence(state, _, __, rootGetters) {
      return eventOccurrenceId => {
        if (rootGetters["auth/isAuthenticated"]) {
          return !!state.subscriptions[eventOccurrenceId]
        }

        return false
      }
    }
  }
}
