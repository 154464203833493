<template>
  <div class="v-datepicker__wrapper">
    <div class="v-datepicker">
      <div class="v-datepicker__selector">
        <v-icon
          :icon="value ? 'event_available' : 'calendar_today'"
          size="1.5rem"
        ></v-icon>
        <div class="v-datepicker__text ml-1">
          <div v-if="value" class="v-datepicker__date">
            {{ dateText }}
          </div>
          <div v-else class="placeholder">
            {{ placeholder }}
          </div>
        </div>
      </div>
      <input
        type="date"
        :value="formattedDate"
        @input="$emit('input', $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
import { toDate } from "@/lib/date-utils"

export default {
  model: {
    prop: "currentValue",
    event: "change"
  },

  props: {
    value: { type: [String, Number, Date], default: "" },
    placeholder: {
      type: String,
      default: function() {
        return this.$t("components.forms.vDateInput.selectDatePlaceHolder")
      }
    }
  },

  computed: {
    dateText() {
      return this.value ? toDate(this.value) : ""
    },

    formattedDate() {
      return this.value ? toDate(this.value, "YYYY-MM-DD") : ""
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.v-datepicker {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  border: 1px solid $color-ui--grey-50;
  color: $color-ui--grey-60;

  &__selector {
    position: absolute;
    display: flex;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0.75rem 0.5rem;
    align-items: center;
  }

  &__text {
    font-weight: 500;
  }

  &__date {
    color: $color-ui--dark-blue;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    box-sizing: border-box;
  }

  input::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
}
</style>
