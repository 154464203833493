import ApiClient from "@/lib/api-client"

export default {
  namespaced: true,

  state: {
    subscriberProperties: {},
    submittedProperties: null
  },

  actions: {
    fetchSubscriberProperties({ commit }) {
      return ApiClient.get("/subscriber/properties").then(res => {
        // turn array of objects into object of objects by slug
        const subscriberProperties = res.data.subscriber_properties.reduce(
          (acc, subscriberProperty) => {
            acc[subscriberProperty.slug] = subscriberProperty
            return acc
          },
          {}
        )

        commit("setProperties", subscriberProperties || {})
      })
    },

    fetchSubmittedProperties({ commit }) {
      return ApiClient.get("/subscriber/submitted_properties").then(res => {
        commit("setSubmittedProperties", res.data.properties || {})
      })
    },

    updateSubmittedProperty({ commit }, { slug, value }) {
      const s = slug
      const v = value
      return ApiClient.put(`/subscriber/properties/${slug}`, {
        value
      }).then(() => {
        commit("setSubmittedProperty", { slug: s, value: v })
      })
    },

    fetchProperty({ commit, getters }, slug) {
      // check if we already have the property
      if (getters.getSubscriberPropertyBySlug(slug)) {
        return Promise.resolve(getters.getSubscriberPropertyBySlug(slug))
      }

      return ApiClient.get(`/subscriber/properties/${slug}`).then(res => {
        commit("setProperty", res.data.subscriber_property)

        return res.data.subscriber_property
      })
    }
  },

  mutations: {
    setProperties(state, subscriberProperties) {
      state.subscriberProperties = subscriberProperties
    },

    setProperty(state, property) {
      state.subscriberProperties[property.slug] = property
    },

    setSubmittedProperties(state, submittedProperties) {
      state.submittedProperties = submittedProperties
    },

    setSubmittedProperty(state, { slug, value }) {
      state.submittedProperties = {
        ...(state.submittedProperties || {}),
        [slug]: value
      }
    }
  },

  getters: {
    getSubscriberPropertyBySlug: state => slug => {
      return state.subscriberProperties[slug]
    },

    allPropertiesList: state => {
      return Object.values(state.subscriberProperties)
    },

    subscriberSubmittedProperties: state => {
      return state.submittedProperties || {}
    },

    areSubmittedPropertiesLoaded: state => {
      return !!state.submittedProperties
    }
  }
}
