<template>
  <v-modal short @close="closePropertyModal">
    <template :slot="PROPERTY_MODAL">
      <form
        v-if="editingProperty"
        class="px-2 py-3"
        @submit.prevent="updateProperty"
      >
        <form-field
          is-subscriber-property
          :field="fieldBySubscriberProperty"
          :value="propertyValue"
          class="mb-3"
          @input="value => (propertyValue = value)"
        />

        <cta-row white-bg overlay-modal row>
          <v-button
            primary
            block
            type="submit"
            :disabled="editingProperty.is_required && !propertyValue.value"
            :is-loading="isLoading"
            @click="updateProperty"
          >
            {{ $t("components.modals.editSubscriberPropertyModal.updateBtn") }}
            {{ editingProperty.name }}
          </v-button>
        </cta-row>
      </form>
    </template>
  </v-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { removeFromHash } from "@/lib/url-helpers"

import FormField from "@/components/FormField"
import CtaRow from "@/components/CtaRow"

export const PROPERTY_MODAL = "#edit-property"

export default {
  components: {
    FormField,
    CtaRow
  },

  props: {
    editingProperty: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      propertyValue: { value: "" },
      isLoading: false,
      PROPERTY_MODAL
    }
  },

  mounted() {
    this.propertyValue = { value: this.savedValue }

    if (!this.areSubmittedPropertiesLoaded) {
      this.fetchSubmittedProperties()
    }
  },

  methods: {
    ...mapActions("subscriberProperties", [
      "updateSubmittedProperty",
      "fetchSubmittedProperties"
    ]),

    updateProperty() {
      this.isLoading = true

      this.updateSubmittedProperty({
        slug: this.editingProperty.slug,
        value: this.propertyValue.value
      })
        .then(() => {
          this.$notify("Successfully Updated")

          this.closePropertyModal()
        })
        .catch(err => {
          this.$notify(err?.message || "Something went wrong")
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    closePropertyModal() {
      this.$emit("close")
      this.propertyValue.value = ""
      // remove from hash
      this.$router.push(removeFromHash(this.$route.hash, PROPERTY_MODAL))
    }
  },

  computed: {
    ...mapGetters("subscriberProperties", [
      "subscriberSubmittedProperties",
      "areSubmittedPropertiesLoaded"
    ]),

    fieldBySubscriberProperty() {
      return {
        id: this.editingProperty.id,
        label: this.editingProperty.label,
        field_type: this.editingProperty.field_type,
        allow_multiple: this.editingProperty.allow_multiple,
        description: this.editingProperty.description,
        is_required: this.editingProperty.is_required,
        show_on_member_profile: this.editingProperty.show_on_member_profile,
        options: (this.editingProperty.options || "").split("|").map(t => ({
          label: t,
          value: t
        }))
      }
    },

    savedValue() {
      return this.subscriberSubmittedProperties[this.editingProperty.slug] || ""
    }
  },

  watch: {
    savedValue() {
      this.propertyValue = { value: this.savedValue }
    }
  }
}
</script>
