import { mapState, mapGetters, mapActions } from "vuex"

import EventBus, { EVENTS } from "@/lib/event-bus"
import {
  isBlockActionLink,
  BLOCK_ACTIONS,
  triggerBlockAction
} from "@/lib/stream-utils"

import { isPresent, isWebLink, isBuilderContext } from "@/lib/utils"
import openImage from "@/lib/mk-lightbox"

export default {
  computed: {
    ...mapState("forms", ["submissionsCount"]),
    ...mapGetters(["app"]),
    ...mapGetters("auth", ["hasCheckedAuthentication", "hasPurchasedProduct"]),

    hasPurchased() {
      if (!this.isTriggerPurchase || !this.blockAction.products) return true

      const productIds = this.blockAction.products.map(item => item.id)

      return this.hasPurchasedProduct(productIds)
    },

    isActionQueryPresent() {
      return isPresent(this.$route.query["block-action"])
    },

    isPreview() {
      return isBuilderContext()
    },

    isShareLink() {
      return (
        this.hasBlockAction &&
        this.blockAction.action === BLOCK_ACTIONS.SHARE &&
        isPresent(this.blockAction.url)
      )
    },

    serializedLink() {
      return this.hasBlockAction && isPresent(this.blockAction.link)
        ? this.blockAction.link.replace(this.app?.url || "", "")
        : null
    },

    hasBlockAction() {
      return isPresent(this.blockAction)
    },

    shouldHandleAccess() {
      return (
        this.hasBlockAction &&
        this.blockAction.action === BLOCK_ACTIONS.OPEN_PAGE &&
        isPresent(this.blockAction.page_id)
      )
    },

    pageId() {
      return this.hasBlockAction ? this.blockAction.page_id : null
    },

    isWebLink() {
      return isWebLink(this.serializedLink)
    },

    isTriggerable() {
      return [
        BLOCK_ACTIONS.TRIGGER_FORM,
        BLOCK_ACTIONS.TRIGGER_OVERLAY,
        BLOCK_ACTIONS.TRIGGER_PURCHASE
      ].includes(this.blockAction.action)
    },

    isTriggerPurchase() {
      return (
        this.hasBlockAction &&
        this.blockAction.action === BLOCK_ACTIONS.TRIGGER_PURCHASE
      )
    },

    isLink() {
      const { link, action } = this.blockAction
      return isBlockActionLink(action) && isPresent(link)
    },

    isOpenLightbox() {
      return (
        this.hasBlockAction &&
        this.blockAction.action === BLOCK_ACTIONS.OPEN_LIGHTBOX
      )
    },

    linkTarget() {
      const { link } = this.blockAction
      return isWebLink(link) ? "_blank" : null
    },

    link() {
      return this.blockAction.link || null
    }
  },

  watch: {
    hasCheckedAuthentication: {
      immediate: true,
      handler: function() {
        if (this.hasCheckedAuthentication && this.isTriggerPurchase) {
          this.$emit("purchaseChecked", this.hasPurchased)
        }
      }
    }
  },

  methods: {
    ...mapActions("forms", ["hasSubmittedForm"]),

    openImage() {
      const imageEl = this.$el.querySelector(`[data-lightbox-image]`)

      if (!imageEl) return

      openImage({ element: imageEl })
    },

    async trigger() {
      let skip = false

      switch (this.blockAction.action) {
        case BLOCK_ACTIONS.TRIGGER_FORM:
          if (
            isPresent(this.blockAction.form_id) &&
            isPresent(this.blockAction.secondary_action)
          ) {
            skip = await this.hasSubmittedForm(this.blockAction.form_id)
          }
          break

        default:
          break
      }

      if (skip) {
        EventBus.$emit(
          EVENTS.TRIGGER_BLOCK_ACTION,
          this.blockAction.secondary_action
        )
      } else {
        triggerBlockAction(this.blockAction, this.hasPurchased)
      }
    }
  }
}
