import Vue from "vue"

import vPage from "@/components/_global/vPage"
import vButton from "@/components/_global/vButton"
import vModal from "@/components/_global/vModal"
import vLink from "@/components/_global/vLink"
import vIcon from "@/components/_global/vIcon"
import vBlockLink from "@/components/_global/vBlockLink"
import vMadeWithMovement from "@/components/_global/vMadeWithMovement"
import vLoader from "@/components/_global/vLoader"

Vue.component("v-page", vPage)
Vue.component("v-button", vButton)
Vue.component("v-modal", vModal)
Vue.component("v-link", vLink)
Vue.component("v-icon", vIcon)
Vue.component("v-block-link", vBlockLink)
Vue.component("v-made-with-movement", vMadeWithMovement)
Vue.component("v-loader", vLoader)
Vue.component("v-pull-hook", () => import("@/components/_global/vPullHook"))
