import Pusher from "pusher-js"
import ApiClient from "@/lib/api-client"

class PusherClient {
  constructor() {
    this.pusherKey = process.env.VUE_APP_PUSHER_KEY
    this.pusherCluster = process.env.VUE_APP_PUSHER_CLUSTER
  }

  get pusher() {
    if (!this.pusherClient) {
      this.pusherClient = new Pusher(this.pusherKey, {
        cluster: this.pusherCluster,
        authorizer: function(channel) {
          return {
            authorize: function(socketId, callback) {
              ApiClient.post("/pusher/auth", {
                socket_id: socketId,
                channel_name: channel.name
              }).then(({ data }) => {
                callback(null, data)
              })
            }
          }
        }
      })
    }

    return this.pusherClient
  }

  subscribe({ channelId }, presence = true) {
    const privateChannel = this._findOrCreateConnectionToChannel(
      channelId.startsWith("private-") ? channelId : `private-${channelId}`
    )

    const presenceChannel =
      presence && !channelId.startsWith("private-")
        ? this._findOrCreateConnectionToChannel(`presence-${channelId}`)
        : null

    return [privateChannel, presenceChannel]
  }

  unsubscribe(channelName) {
    this.pusher.unsubscribe(channelName)

    return true
  }

  _findOrCreateConnectionToChannel(channelId) {
    const existingChannel = this.pusher.channel(channelId)
    return existingChannel ? existingChannel : this.pusher.subscribe(channelId)
  }
}

export default new PusherClient()
