<script>
export default {
  functional: true,

  props: {
    white: { type: Boolean, default: false },
    thin: { type: Boolean, default: false }
  },

  render(h, { props }) {
    return (
      <div
        class={`v-loader ${props.white ? "--white" : null} ${
          props.thin ? "--thin" : null
        }`}
      />
    )
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.v-loader {
  @include loader-color($color-ui--grey-80);

  width: $size--8;
  height: $size--8;
  margin: 0;
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid transparent;
  border-right: 3px solid;
  border-bottom: 3px solid;
  border-left: 3px solid;
  transform: translateZ(0);
  animation: spin 1.1s infinite ease;
  border-radius: 50%;

  &.--white {
    @include loader-color(white);
  }

  &.--thin {
    border-right-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 2px;
  }
}
</style>
