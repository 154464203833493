import Vue from "vue"
import { DirectiveOptions, VNode } from "vue"

// Extend HTMLElement to include custom properties
interface HorizontalScrollElement extends HTMLElement {
  _horizontalScroll?: {
    onScroll: EventListenerOrEventListenerObject
    checkOverflow: () => void
    scrollLeft: () => void
    scrollRight: () => void
    leftButton: HTMLButtonElement
    rightButton: HTMLButtonElement
    scrollByAmount: number
  }
}

interface BindingValue {
  attachTo?: string | HTMLElement
  scrollBy?: number
  xOffset?: string
  yOffset?: string
}

const horizontalScroll: DirectiveOptions = {
  inserted(el: HorizontalScrollElement, binding, _vnode: VNode) {
    const bindingValue = (binding.value as BindingValue) || {}

    // Determine the positioning element based on 'attachTo' in binding value
    let positioningElement: HTMLElement | null = null

    if (bindingValue.attachTo) {
      if (typeof bindingValue.attachTo === "string") {
        // If 'attachTo' is a selector string
        positioningElement = el.closest(bindingValue.attachTo)
      } else if (bindingValue.attachTo instanceof HTMLElement) {
        // If 'attachTo' is an HTML element
        positioningElement = bindingValue.attachTo
      }
    } else {
      // Default to using the parent element
      positioningElement = el.parentElement
    }

    if (!positioningElement) {
      console.warn("v-horizontal-scroll: Could not find positioning element.")
      positioningElement = el // Fallback to the scroll container itself
    }

    // Ensure the positioning element has position: relative
    if (getComputedStyle(positioningElement).position === "static") {
      positioningElement.style.position = "relative"
    }

    // Create left and right buttons
    const leftButton = document.createElement("button")
    const rightButton = document.createElement("button")

    leftButton.innerHTML = `
      <span class="material-icons-round">
        chevron_left
      </span>
    `
    rightButton.innerHTML = `
      <span class="material-icons-round">
        chevron_right
      </span>
    `

    leftButton.className = "scroll-button left"
    rightButton.className = "scroll-button right"

    // Initially hide buttons
    leftButton.style.display = "none"
    rightButton.style.display = "none"

    if (bindingValue.xOffset) {
      leftButton.style.left = bindingValue.xOffset
      rightButton.style.right = bindingValue.xOffset
    }

    if (bindingValue.yOffset) {
      leftButton.style.top = bindingValue.yOffset
      rightButton.style.top = bindingValue.yOffset
    }

    // Append buttons to the positioning element
    positioningElement.appendChild(leftButton)
    positioningElement.appendChild(rightButton)

    // Determine scroll amount
    const scrollByAmount = bindingValue.scrollBy ?? 200 // Default to 200 if not specified

    // Event handlers
    const checkOverflow = () => {
      // const isOverflowing = el.scrollWidth > el.clientWidth
      leftButton.style.display = el.scrollLeft > 0 ? "flex" : "none"
      rightButton.style.display =
        el.scrollLeft + el.clientWidth < el.scrollWidth ? "flex" : "none"
    }

    const onScroll = () => {
      checkOverflow()
    }

    const scrollLeft = () => {
      el.scrollBy({ left: -scrollByAmount, behavior: "smooth" })
    }

    const scrollRight = () => {
      el.scrollBy({ left: scrollByAmount, behavior: "smooth" })
    }

    // Add event listeners
    el.addEventListener("scroll", onScroll)
    window.addEventListener("resize", () => {
      checkOverflow()
    })
    leftButton.addEventListener("click", scrollLeft)
    rightButton.addEventListener("click", scrollRight)

    // Store references for cleanup
    el._horizontalScroll = {
      onScroll,
      checkOverflow,
      scrollLeft,
      scrollRight,
      leftButton,
      rightButton,
      scrollByAmount
    }

    // Initial check
    checkOverflow()
  },
  componentUpdated(el: HorizontalScrollElement) {
    Vue.nextTick(() => {
      el._horizontalScroll?.checkOverflow()
    })
  },
  unbind(el: HorizontalScrollElement) {
    const hs = el._horizontalScroll
    if (hs) {
      // Remove event listeners
      el.removeEventListener("scroll", hs.onScroll)
      window.removeEventListener("resize", hs.checkOverflow)
      hs.leftButton.removeEventListener("click", hs.scrollLeft)
      hs.rightButton.removeEventListener("click", hs.scrollRight)

      // Remove buttons from the positioning element
      hs.leftButton.parentNode?.removeChild(hs.leftButton)
      hs.rightButton.parentNode?.removeChild(hs.rightButton)

      // Clean up
      delete el._horizontalScroll
    }
  }
}

export default horizontalScroll
