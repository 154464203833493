var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('portal',{attrs:{"selector":"#modal-portal"}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.isOpen)?_c('div',{staticClass:"p-fixed v-modal__backdrop",style:({ zIndex: 150 + _vm.zIndex }),on:{"click":_vm.closeModal}}):_vm._e()]),_c('transition',{attrs:{"name":_vm.transitionModalName}},[(_vm.isOpen)?_c('div',{class:[
        {
          '--short': _vm.short,
          '--x-short': _vm.xShort,
          '--video-fullscreen': _vm.isFullscreen,
          '--w-overlay': !!_vm.$slots.overlay,
          '--vertical': _vm.vertical
        },
        _vm.modalClass,
        'p-fixed v-modal'
      ],style:({ zIndex: 150 + _vm.zIndex + 1 }),attrs:{"data-role":"modal","data-cy":"modal"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeModal.apply(null, arguments)}}},[_c('div',{staticClass:"p-relative v-modal__content"},[(!_vm.disableClose)?_c('div',{staticClass:"p-absolute v-modal__close",attrs:{"data-cy":"close-modal"}},[_c('v-button',{attrs:{"round":"","outlined":"","secondary":""},on:{"click":_vm.closeModal}},[_c('v-icon',{attrs:{"size":"24px","icon":"close","color":"white"}})],1)],1):_vm._e(),(_vm.$slots.overlay)?_c('div',{staticClass:"v-modal__overlay container",style:({
            '--app-height': ("calc(" + (_vm.appHeight.height) + "px - " + _vm.pageContentHeight + "px + 12px)")
          }),attrs:{"data-cy":"modal-overlay"}},[_vm._t("overlay")],2):_vm._e(),_c('div',{staticClass:"v-modal__content-wrapper",attrs:{"data-role":"modal-content-wrapper"}},[_c('div',{staticClass:"v-modal__before container"},[_vm._t("before")],2),_c('transition',{attrs:{"name":"fade-out-left","mode":"out-in"}},[_c('div',{key:_vm.activeHash,staticClass:"v-modal__page container"},[_vm._t(_vm.activeHash)],2)]),_vm._t("after")],2)])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }