import enumValidator from "@/lib/validators/enum-validator"

export default (values, allowEmpty = true) => {
  if (!(values instanceof Array)) throw "Provided values must be an array"
  if (!values.length) return true

  return val => {
    if (!val || !val.length) return allowEmpty

    return !val.split(" ").find(val => !enumValidator(values)(val))
  }
}
