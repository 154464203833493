<template>
  <div
    v-if="showFitterBadge"
    ref="badge"
    :class="[
      'made-with-movement',
      {
        '--allow-fixed': allowFixed
      }
    ]"
  >
    <a :href="url" target="_blank" class="d-flex align-items-center">
      <img
        v-if="isDarkMode"
        :src="require('@/assets/images/made-with-movement-white.svg')"
        alt="Made With Movement"
      />
      <img
        v-else
        :src="require('@/assets/images/made-with-movement-black.svg')"
        alt="Made With Movement"
      />
    </a>
  </div>
</template>

<script>
import {
  isDarkTextColor,
  rgbToHex,
  APP_COLOR_HEX
} from "@/lib/determine-text-color"

import { mapState, mapGetters } from "vuex"
import { COLOR_MODES } from "@/lib/stream-utils"

export default {
  props: {
    autoDetectColor: { type: Boolean, default: false },
    allowFixed: { type: Boolean, default: true }
  },

  data() {
    return {
      colorModeOverride: null
    }
  },

  computed: {
    ...mapState(["global"]),
    ...mapGetters(["showFitterBadge", "app"]),

    colorMode() {
      return this.colorModeOverride
        ? this.colorModeOverride
        : this.global?.theme?.default_color_mode
    },

    isDarkMode() {
      return this.colorMode === COLOR_MODES.DARK
    },

    url() {
      let url =
        "https://movement.so?utm_campaign=made-with-movement&utm_medium=made-with-movement"

      if (this.app.slug) {
        url += `&utm_source=${this.app.slug}`
      }

      if (this.app.platform_rewardful_slug) {
        url += `&via=${this.app.platform_rewardful_slug}`
      }

      return url
    }
  },

  mounted() {
    if (this.autoDetectColor) {
      this.detectColor()
    }
  },

  methods: {
    detectColor() {
      this.$nextTick(() => {
        if (this.$refs.badge) {
          const style = getComputedStyle(this.$refs.badge)

          this.colorModeOverride = isDarkTextColor(
            rgbToHex(style.getPropertyValue("background-color")) ||
              APP_COLOR_HEX.WHITE
          )
            ? COLOR_MODES.LIGHT
            : COLOR_MODES.DARK
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.made-with-movement {
  width: 160px;
  margin: $size--10 auto $size--11;
  background-color: inherit;

  a {
    @include clickable;

    border-radius: $size--1;
    padding: $size--1;
  }

  img {
    max-width: 100%;
    max-height: 24px;
  }
}

@media screen and (max-width: $breakpoint--xl) {
  .made-with-movement {
    transform: none !important;
  }
}

@media screen and (min-width: $breakpoint--xl) {
  .made-with-movement.--allow-fixed {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: $tabbar--desktop-width;
    height: $made-with-movement-badge--height;
    padding: 0.75rem;
    margin: 0;
    left: 0;
    bottom: 0;
    box-shadow: 0 -1px 3px 0 rgba($color-rgb--grey-80, 0.1);

    a {
      display: block;
      padding: $size--4;
    }
  }
}
</style>
