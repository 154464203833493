<script>
export default {
  functional: true,

  props: {
    noInnerScroll: { type: Boolean, default: false },
    noPadding: { type: Boolean, default: false },
    white: { type: Boolean, default: false },
    darkMode: { type: Boolean, default: false },
    pageClass: { type: String, default: "" },
    pageId: { type: String, default: "" }
  },

  render(h, { slots, props, data }) {
    const contentClasses = ["v-page", data.staticClass]

    if (props.pageClass) contentClasses.push(props.pageClass)
    if (props.noInnerScroll) contentClasses.push("--no-scroll")
    if (props.noPadding) contentClasses.push("--no-bottom-padding")
    if (props.white) contentClasses.push("--white")
    if (props.darkMode) contentClasses.push("--dark-mode")

    return (
      <div id={props.pageId} class={contentClasses.join(" ")}>
        {slots().default}
      </div>
    )
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.v-page {
  padding-bottom: 10rem;
  margin: 0 auto;
  background: $color-ui--grey-10;
  min-height: 100vh;

  &.--no-scroll {
    position: relative;
  }

  &.--white {
    background: $color-ui--white;
  }

  &.--no-bottom-padding {
    padding-bottom: 0;
  }
}
</style>

<style lang="scss">
@import "@/assets/styles/variables";

@media screen and (min-width: $breakpoint--xl) {
  .v-page {
    padding-left: $tabbar--desktop-width;

    [data-role="fixed-toolbar"].v-toolbar__wrapper .v-toolbar {
      padding-left: 250px;
    }

    .v-tabs {
      .v-page {
        padding-left: initial;
      }
    }
  }
}
</style>
