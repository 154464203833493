<script>
export default {
  functional: true,

  props: {
    width: { type: [String, Number], default: "100%" },
    height: { type: [String, Number], default: "56px" },
    round: { type: Boolean, default: false },
    fullWidth: { type: Boolean, default: false }
  },

  render(h, { props, data }) {
    const classList = [
      "placeholder-rect",
      props.round ? "--round" : "",
      props.fullWidth ? "--full-width" : "",
      data.staticClass,
      data.class
    ]

    return (
      <div
        class={classList}
        style={`height: ${props.height}; width: ${props.width}; ${Object.keys(
          data.staticStyle || {}
        )
          .map(k => `${k}: ${data.staticStyle[k]};`)
          .join(" ")}`}
      />
    )
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.placeholder-rect {
  background: $color-ui--grey-20;
  border-radius: var(--border-radius);
  display: block;
  margin: $size--4;
  animation: load-pulse 1.2s ease infinite;

  &.--round {
    border-radius: 1000px;
  }

  &:not(.--full-width) {
    max-width: calc(100% - #{$size--4 * 2});
  }
}
</style>
