import { isPresent } from "@/lib/utils"

export default {
  namespaced: true,

  state: {
    appSubscription: null
  },

  mutations: {
    setAppSubscription(state, subscription) {
      // TODO: Remove
      state.appSubscription = isPresent(subscription) ? subscription : null
    }
  },

  getters: {
    isSubscriberOnly(_, __, state) {
      return state.global?.access.is_subscriber_only
    },

    isPrivateApp(_, __, state) {
      return !!state.global?.access.is_private
    },

    isSubscribedToApp(state, __, ___, getters) {
      if (getters["auth/isAuthenticated"]) {
        return !!state.appSubscription
      } else {
        return false
      }
    },

    hasAccessViaSubscription(_, getters) {
      return getters.isSubscriberOnly && getters.isSubscribedToApp
    },

    appPurchaseOverlayId(_, __, state) {
      return state.global?.access.purchase_overlay?.id
    }
  }
}
