<script>
export default {
  functional: true,

  props: {
    to: { type: String, default: null }
  },

  render(h, { props, listeners, slots, data }) {
    const classes = [
      "v-block-link d-flex align-items-center",
      data.class,
      data.staticClass
    ]
    return (
      <v-link {...{ props, on: listeners }} class={classes}>
        {slots().default}
      </v-link>
    )
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";

.v-block-link {
  background: $color-ui--white;
  border-radius: var(--border-radius);
  box-shadow: $box-shadow--medium;
  color: $color-ui--black;
  font-weight: $font-weight--semi-bold;
  font-family: var(--body-font-stack);
  font-size: $font-size--base;

  .v-icon {
    transform: scale(1.4);
    color: $color-ui--grey-80;
  }
}
</style>
