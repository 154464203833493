import Vue from "vue"
import ApiClient from "@/lib/api-client"

export default {
  namespaced: true,

  state: {
    subscriptions: {}
  },

  actions: {
    fetchPacks() {
      return ApiClient.get("/packages").then(res => res.data.packs)
    },

    fetchSubscriptions({ commit }) {
      return ApiClient.get("/packages/subscriptions").then(res => {
        commit("setPackSubscriptions", res.data.pack_subscriptions)
      })
    },

    fetchPackAccess(_, packId) {
      return ApiClient.get(`/packages/${packId}/access`)
    }
  },

  mutations: {
    setPackSubscriptions(state, subscriptions) {
      Vue.set(state, "subscriptions", subscriptions)
    }
  },

  getters: {
    userPackSubscription(state, _, __, rootGetters) {
      return packId => {
        if (rootGetters["auth/isAuthenticated"]) {
          return state.subscriptions[packId]
        }

        return null
      }
    },

    userHasPackSubscriptions(state) {
      return Object.keys(state.subscriptions).length > 0
    }
  }
}
