<template>
  <textarea
    :class="['v-textarea', { '--small': small }]"
    :value="value"
    @input="$emit('input', $event.target.value, $event.target)"
    @focus="e => $emit('focus', e)"
    @blur="e => $emit('blur', e)"
    @keydown.enter.exact="e => $emit('enter', e)"
    @keydown="e => $emit('keydown', e)"
    @keyup="e => $emit('keyup', e)"
  />
</template>

<script>
export default {
  props: {
    value: { type: String, required: true },
    small: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";

.v-textarea {
  appearance: none;
  position: relative;
  width: 100%;
  padding: $size--2 $size--4;
  color: $color-ui--grey-80;
  font-size: $font-size--base;
  min-height: 7em;
  line-height: 1.5;
  border: 2px solid $color-ui--grey-20;
  border-radius: var(--input-border-radius);
  transition: all 0.08s ease-in;
  background: $color-ui--white;
  box-shadow: 0 0 0 0 rgba(var(--primary-brand-color-rgb-vals), 0.16);
  font-family: inherit;

  &.--small {
    padding: $size--2;
    font-size: $font-size--small;
  }

  &:focus {
    border: 2px solid rgba(var(--primary-brand-color-rgb-vals), 0.75);
    box-shadow: 0 0 0 4px rgba(var(--primary-brand-color-rgb-vals), 0.16);
  }

  &:disabled {
    background: rgba(black, 0.1);
    opacity: 0.6;
    box-shadow: none;
  }
}
</style>
