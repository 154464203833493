// // https://medium.com/better-programming/generate-contrasting-text-for-your-random-background-color-ac302dc87b4
type RGB = { r: number; g: number; b: number }

interface Options {
  threshold?: number
  black?: string
  white?: string
}

const rgbToYIQ = ({ r, g, b }: RGB): number =>
  (r * 299 + g * 587 + b * 114) / 1000

const hexToRgb = (hex: string): RGB | undefined => {
  if (!hex || hex === undefined || hex === "") {
    return undefined
  }

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : undefined
}

export const rgbToHex = (rgb: string | undefined): string | undefined => {
  if (!rgb || rgb === undefined || rgb === "") {
    return undefined
  }

  const standardRgb = rgb.split("(")[1]?.split(")")[0] || rgb
  const rgbArray = standardRgb.split(",")

  return (
    "#" +
    rgbArray
      .map(e => {
        const x = parseInt(e).toString(16)

        return x.length == 1 ? "0" + x : x
      })
      .join("")
  )
}

export const APP_COLOR_HEX = {
  BLACK: "#000000",
  WHITE: "#ffffff",
  LIGHT_GREY: "#dde3e9", // color-fixed--grey-20
  GREY: "#8795a8" // color-fixed--grey-60
} as const

export const isDarkTextColor = (
  colorHex: string,
  options: Options = {}
): boolean => {
  const threshold = options.threshold || 150

  if (colorHex === undefined) {
    return true
  }

  const rgb = hexToRgb(colorHex)

  if (rgb === undefined) {
    return true
  }

  return rgbToYIQ(rgb) >= threshold
}

export default (
  colorHex: string | null | undefined,
  options: Options = {}
): string => {
  const _options = {
    threshold: 150,
    black: APP_COLOR_HEX.BLACK,
    white: APP_COLOR_HEX.WHITE,
    ...options
  }

  return !colorHex || isDarkTextColor(colorHex, _options)
    ? _options.black
    : _options.white
}
