export const PLAYER_EVENTS = {
  ADD: "add",
  UPDATE: "update",
  INITIALIZE: "initialize",
  UNLOAD: "unload",
  READY: "ready",
  ERROR: "error",
  // control
  PLAY: "play",
  PAUSE: "pause",
  STOP: "stop",
  TIME_UPDATE: "timeupdate",
  VOLUME_CHANGE: "volumechange",
  RATE_CHANGE: "ratechange",
  SEEK: "seek",
  ENDED: "ended",
  REPLAY: "replay",
  REWIND: "rewind",
  FORWARD: "forward",
  VOLUMECHANGE: "volumechange",
  PROGRESS: "progress",
  BUFFERING: "buffering",
  WAITING: "waiting",
  CAN_PLAY: "canplay"
}

export const ERRORS = {
  NOT_ALLOWED_ERROR: "NotAllowedError",
  HLS_ERROR: "HlsError"
}

export const SUPPORTED_SPEED_RATE = [0.75, 1, 1.25, 1.5, 2]

export const getNextPlaybackSpeed = currentSpeed => {
  // TODOB: specs
  const nextSpeedIndex =
    (SUPPORTED_SPEED_RATE.indexOf(currentSpeed) + 1) %
    SUPPORTED_SPEED_RATE.length

  return SUPPORTED_SPEED_RATE[nextSpeedIndex] || 1
}
