export const addExternalLinks = links => {
  links.forEach(link => {
    switch (link.link_type) {
      case "script": {
        const script = document.createElement("script")
        script.src = link.link_url
        script.type = "text/javascript"
        document.body.appendChild(script)

        break
      }
      case "css": {
        const linkElement = document.createElement("link")
        linkElement.href = link.link_url
        linkElement.rel = "stylesheet"
        linkElement.type = "text/css"
        document.head.appendChild(linkElement)

        break
      }
    }
  })
}
