<template>
  <div v-if="isPreview">
    {{ /* Disable Block Actions in preview */ }}
    <slot />
  </div>
  <div v-else-if="!hasBlockAction">
    <slot />
  </div>
  <share v-else-if="isShareLink" :url="blockAction.url">
    <slot />
  </share>
  <a
    v-else-if="isLink && isWebLink"
    :href="link"
    :target="linkTarget"
    class="text-decoration-none"
  >
    <slot />
  </a>
  <v-link
    v-else-if="isLink && shouldHandleAccess"
    :to="serializedLink"
    with-access-checks
    :page-id="pageId"
    :parent-page-id="parentPageId"
  >
    <template v-slot="{ isLoadingAccess, isLocked, availableAfter }">
      <slot
        :is-loading-access="isLoadingAccess"
        :is-locked="isLocked"
        :available-after="availableAfter"
      />
    </template>
  </v-link>
  <v-link v-else-if="isLink" :to="serializedLink">
    <slot />
  </v-link>
  <div v-else-if="isTriggerable" @click="trigger">
    <slot />
  </div>
  <div v-else-if="isOpenLightbox" @click.stop="openImage">
    <slot />
  </div>
  <div v-else>
    <slot />
  </div>
</template>

<script>
import BlockAction from "@/lib/mixins/block-action"

export default {
  mixins: [BlockAction],

  components: {
    Share: () => import("@/components/block-actions/Share")
  },

  props: {
    blockAction: { type: Object, default: () => ({}) }
  },

  inject: {
    parentPageId: {
      from: "page_id",
      default: null
    }
  }
}
</script>
