import { isIos, isStandalone, isCypress, isAppStore } from "@/lib/is-platform"
import { isEmpty, isPresent } from "@/lib/utils"

export default {
  namespaced: true,

  state: {
    installPrompt: null,
    isDownloading: false,
    hasDownloaded: false
  },

  mutations: {
    setInstallPrompt(state, prompt) {
      state.installPrompt = prompt
    },

    setIsDownloading(state, isDownloading = true) {
      state.isDownloading = isDownloading
    },

    setHasDownloaded(state) {
      state.hasDownloaded = true
    }
  },

  actions: {
    setupInstallPrompt({ commit }) {
      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault()
        commit("setInstallPrompt", e)
      })
    },

    addToHomescreen({ state, commit, dispatch }, context) {
      const track = activityType => {
        dispatch(
          "analytics/trackActivity",
          { activityType, data: { context } },
          { root: true }
        )
      }

      track("app_download_started")

      if (isIos() || isCypress()) {
        commit("setIsDownloading")
      } else {
        const { installPrompt } = state

        if (!installPrompt) return

        commit("setIsDownloading")

        installPrompt.prompt()
        installPrompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === "accepted") {
            track("app_downloaded")
            commit("setHasDownloaded")

            setTimeout(() => commit("setIsDownloading", false), 500)
          } else {
            track("app_download_aborted")
            commit("setIsDownloading", false)
          }
          commit("setInstallPrompt", null)
        })
      }
    }
  },

  getters: {
    canPrompt(state, _, rootState) {
      if (isCypress()) {
        return isPresent(window.canDownload) ? window.canDownload : false
      } else if (isAppStore()) {
        return false
      } else if (isEmpty(rootState.global?.app.icon)) {
        return false
      } else if (isIos() && !isStandalone()) {
        return true
      } else if (!isStandalone() && !!state.installPrompt) {
        return true
      } else {
        return false
      }
    }
  }
}
