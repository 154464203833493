import Vue from "vue"

import { isPresent } from "@/lib/utils"

export const EVENTS = {
  swUpdateAvailable: "swUpdateAvailable",
  toastDismissed: "toastDismissed",
  toastShown: "toastShown",
  WORKOUT_STARTED: "workoutStarted",
  WORKOUT_COMPLETED: "workoutCompleted",
  FORM_CAPTURE_PROGRESS: "formCaptureProgress",
  FORM_PROGRESS_CAPTURED: "formProgressCaptured",
  FORM_SUBMITTED: "formSubmitted",
  FORM_CLOSED: "formClosed",
  SHOW_FORM_SUBMISSION: "showFormSubmission",
  SUBMISSION_VALUES_UPDATED: "submissionValuesUpdated",
  WORKOUT_EXIT_ATTEMPTED: "workoutExitAttempted",
  PAGE_EXIT_ATTEMPTED: "pageExitAttempted",
  WORKOUT_RESUMED: "workoutResumed",
  PROGRAM_STARTED: "programStarted",
  PROGRAM_COMPLETED: "programCompleted",
  AUTH_SIGNED_UP: "authSignedUp",
  AUTH_COMPLETED: "authCompleted",
  AUTH_ABORTED: "authAborted",
  BRIDGE_READY: "bridgeReady",
  TRIGGER_FORM: "triggerForm",
  TRIGGER_CHECKOUT: "triggerCheckout",
  TRIGGER_PAST_DUE_PROMPT: "triggerPastDuePrompt",
  TRIGGER_OVERLAY: "triggerOverlay",
  OPEN_COMPLETABLE_PAGES: "openCompletePages",
  CHECKOUT_COMPLETED: "checkoutCompleted",
  TRIGGER_SCHEDULED_MODAL: "triggerScheduledModal",
  TRIGGER_BLOCK_ACTION: "triggerBlockAction",
  IAP_PURCHASE_CANCELED: "iapPurchaseCanceled",
  IAP_PURCHASE_SUCCESS: "iapPurchaseSuccess",
  IAP_INVALID_PRODUCT: "iapInvalidProduct",
  PROMPT_PUSH_PERMISSION: "promptPushPermission",
  ACTION_PANEL_ACTION: "actionPanelAction",
  SHOW_GLOBAL_LOADER: "showGlobalLoader",
  HIDE_GLOBAL_LOADER: "hideGlobalLoader",
  EXISTING_PRODUCT_SUBSCRIPTION: "existingProductSubscription",
  NATIVE_AUTHENTICATED: "nativeAuthenticated",

  STRIPE_LOADED: "stripeLoaded",

  PAGE_BLOCKS_RENDERED: "pageBlocksRendered",

  // NOTE: Please be intentional using this event. Setting a listener will
  // trigger the "express" auth flow (i.e. no download prompt, or follow on)
  // Ensure you are correctly tearing down any listeners of this event
  POST_AUTH_FLOW: "postAuthFlow",

  // Global post sign up action
  POSTING_SIGN_UP_ACTION: "postingSignUpAction",
  FINISH_SIGN_UP_ACTION: "finishSignUpAction",

  // Builder
  BLOCK_MOUNTED: "blockMounted",

  // Upload
  OPEN_FILE_DIALOG: "openFileDialog"
}

type EventBus = {
  hasListener: (event: string) => boolean
  _events: Record<string, any>
} & Vue

const bus: EventBus = new Vue()

bus.hasListener = event => isPresent(bus._events[event])

export default bus
