<template>
  <div
    :class="[
      {
        '--vertical': isVerticalCard,
        '--horizontal': isHorizontalCard,
        '--raised': isRaised,
        '--invert-colors': !isRaised && invertColors,
        '--completed': isPageCompleted,
        '--event': isEventOccurrence,
        '--no-image': !showImage,
        '--no-uppercase': noUppercase
      },
      'collection-item p-relative',
      cssClass
    ]"
    :data-page-id="pageId"
  >
    <v-link
      class="h-100"
      :is-attachment="isAttachment"
      :with-access-checks="pageId && !unlock"
      :page-id="pageId"
      :parent-page-id="parentPageId"
      :force-lock="isBoolean(unlock) ? !unlock : false"
      :to="to"
    >
      <template v-slot="{ isLocked, isLoadingAccess, availableAfter }">
        <template v-if="shouldShowCountdown && !isHorizontalCard && showImage">
          <countdown
            :is-loading="false"
            :overlay="isVerticalCard"
            :starts-at="schedule.starts_at"
            :ends-at="schedule.ends_at"
          />
        </template>

        <div class="collection-item__content">
          <template v-if="isDateCardVariant">
            <event-occurrence-date
              v-if="schedule && schedule.starts_at"
              :date="schedule.starts_at"
            />
          </template>
          <template v-else-if="showImage">
            <template v-if="$slots.image">
              <slot name="image" />
            </template>
            <div
              v-else
              class="collection-item__image-wrapper p-relative"
              :style="{
                width: imageWidth,
                height: imageHeight
              }"
            >
              <div
                class="p-absolute collection-item__image"
                :style="{
                  backgroundImage: isPresent(itemImage)
                    ? `url(${itemImage})`
                    : false
                }"
              >
                <div v-if="showPlayButton" class="p-overlay">
                  <play-button />
                </div>
              </div>
            </div>
          </template>

          <div
            class="collection-item__details p-relative"
            :style="{
              width: detailsWidth
            }"
          >
            <div
              class="collection-item__details-body d-flex justify-content-between w-100"
            >
              <div
                :style="{
                  maxWidth:
                    (showPrice && isPresent(product)) || showBookedBadge
                      ? '75%'
                      : '100%'
                }"
              >
                <div
                  v-if="showPreTitle && hasDate"
                  class="collection-item__pretitle"
                >
                  <event-occurrence-pretitle
                    v-if="isEventOccurrence"
                    :starts-at="schedule.starts_at"
                    :ends-at="schedule.ends_at"
                    :is-all-day="schedule.is_all_day"
                  />
                  <scheduled-page-pretitle
                    v-else-if="showTime"
                    :starts-at="schedule.starts_at"
                    :ends-at="schedule.ends_at"
                    :is-all-day="schedule.is_all_day"
                    :show-all-day="isEventOccurrence"
                    :timezone="renderTimezone"
                  />
                </div>
                <h3 v-if="isPresent(title)" class="collection-item__title">
                  {{ title }}
                </h3>
                <h6
                  v-if="
                    (isPresent(type) || isPresent(contentType)) &&
                      !isEventOccurrence &&
                      !isGenericPage
                  "
                  class="collection-item__type"
                >
                  {{ contentType ? contentType.replaceAll("-", " ") : type }}
                </h6>
                <p v-if="isPresent(subtitle)" class="collection-item__subtitle">
                  {{ subtitle }}
                </p>
              </div>
              <div
                v-if="!isLoadingAccess"
                class="d-flex justify-content-center flex-column"
              >
                <div
                  v-if="showPrice && isPresent(product)"
                  class="collection-item__price"
                >
                  {{ `${product.symbol || "$"}${product.price_dollars}` }}
                </div>

                <div v-if="showBookedBadge" class="collection-item__book">
                  <div
                    v-if="isSubscribedToPage(pageId)"
                    class="badge badge-success"
                  >
                    {{ $t("components.collectionItem.booked") }}
                  </div>
                  <div v-else class="badge">
                    {{ $t("components.collectionItem.book") }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showFooter" class="collection-item__footer pt-1">
              <event-occurrence-footer
                v-if="isEventOccurrence"
                :isVirtual="itemData.is_virtual"
              />
            </div>
          </div>

          <template v-if="isLocked || isLoadingAccess || isLoading">
            <access-lock
              :is-loading="isLoadingAccess || isLoading"
              :overlay="isVerticalCard"
              :horizontal="isHorizontalCard"
              :available-after="availableAfter"
            />
          </template>

          <template v-if="!(isLocked || isLoadingAccess) && isCompletable">
            <complete-check
              :complete="isPageCompleted"
              :overlay="!isRaised && isVerticalCard"
              :horizontal="isHorizontalCard"
            />
          </template>

          <template
            v-if="
              showProgress &&
                !showBookedBadge &&
                !isCompletable &&
                !(isLocked || isLoadingAccess) &&
                isPresent(pageProgress) &&
                pageProgress.has_started
            "
          >
            <completion-percent
              :percent-complete="pageProgress.percent_complete"
            />
          </template>
        </div>
      </template>
    </v-link>

    <slot name="subitems" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import { getImageUrl, isEmpty, isPresent, isBoolean } from "@/lib/utils"
import { withRatio } from "@shared/block-ratio"

import enumValidator from "@/lib/validators/enum-validator"
import stringEnumValidator from "@/lib/validators/string-enum-validator"
import { getOngoingOrUpcomingSchedule } from "@/lib/schedule-utils"

const HORIZONTAL = "horizontal"

export default {
  components: {
    AccessLock: () => import("@/components/AccessLock"),
    EventOccurrencePretitle: () =>
      import("@/components/collection-item/EventOccurrencePretitle"),
    EventOccurrenceFooter: () =>
      import("@/components/collection-item/EventOccurrenceFooter"),
    EventOccurrenceDate: () =>
      import("@/components/collection-item/EventOccurrenceDate"),
    CompleteCheck: () => import("@/components/collection-item/CompleteCheck"),
    CompletionPercent: () =>
      import("@/components/page-actions/progress/CompletionPercent"),
    ScheduledPagePretitle: () =>
      import("@/components/collection-item/ScheduledPagePretitle"),
    Countdown: () => import("@/components/collection-item/Countdown"),
    PlayButton: () => import("@/components/PlayButton")
  },

  props: {
    cardDisplay: {
      type: String,
      default: "vertical",
      validator: enumValidator(["horizontal", "vertical"])
    },
    cardStyle: {
      type: String,
      default: "raised",
      validator: enumValidator(["flat", "raised"])
    },
    modifiers: {
      type: String,
      default: "",
      validator: stringEnumValidator(["date-card", "no-image"])
    },
    imageSize: {
      type: Number,
      default: 100
    },
    image: { type: Object, default: () => ({}) },
    video: { type: Object, default: () => ({}) },
    product: { type: Object, default: () => ({}) },
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    type: { type: String, default: null },
    contentType: { contentType: String, default: null },
    to: { type: String, default: null },
    showPrice: { type: Boolean, default: true },
    showTime: { type: Boolean, default: true },
    itemData: { type: Object, default: null },
    invertColors: { type: Boolean, default: false },
    pageId: { type: [Number, String], default: null },
    unlock: { type: Boolean, default: null },
    isLoading: { type: Boolean, default: false },
    startDateFormat: { type: String, default: "ddd MMM D" },
    cssClass: { type: String, default: null },
    showBooked: { type: Boolean, default: false },
    showProgress: { type: Boolean, default: true },
    showCountdown: { type: Boolean, default: true },
    noUppercase: { type: Boolean, default: false },
    currentSchedule: { type: Object, default: null },
    isAttachment: { type: Boolean, default: false }
  },

  inject: {
    parentPageId: {
      from: "page_id",
      default: null
    }
  },

  methods: {
    ...mapActions("pages", ["fetchPageData"]),

    getImageUrl,
    isPresent,
    isBoolean
  },

  watch: {
    shouldFetchPageData: {
      immediate: true,
      handler: function() {
        if (this.shouldFetchPageData) {
          this.fetchPageData(this.pageId)
        }
      }
    }
  },

  computed: {
    ...mapGetters(["renderTimezone"]),
    ...mapGetters("auth", ["hasCheckedAuthentication"]),
    ...mapGetters("pages", [
      "isPageComplete",
      "isSubscribedToPage",
      "getPageProgress"
    ]),

    schedule() {
      if (!this.itemData) {
        return null
      }

      if (this.currentSchedule) {
        return {
          starts_at: this.currentSchedule.starts_at,
          ends_at: this.currentSchedule.ends_at,
          is_all_day: this.currentSchedule.is_all_day,
          is_multiday: this.currentSchedule.is_multiday
        }
      } else if (this.itemData.schedules) {
        return getOngoingOrUpcomingSchedule(this.itemData.schedules)
      } else if (this.itemData.starts_at) {
        // Legacy version of CollectionItemSerializer
        return {
          starts_at: this.itemData.starts_at,
          ends_at: this.itemData.ends_at,
          is_all_day: this.itemData.is_all_day,
          is_multiday: this.itemData.is_multiday
        }
      } else {
        return null
      }
    },

    imageHeight() {
      // using percentage to preserve aspect ratio of image for
      // different screens
      // maybe a good idea to set max-height for landscape view
      if (this.cardDisplay === HORIZONTAL) {
        return withRatio(90, this.imageSize)
      } else {
        return withRatio(190, this.imageSize)
      }
    },

    imageWidth() {
      if (this.cardDisplay === HORIZONTAL) {
        return withRatio(30, this.imageSize, { unit: "%" })
      } else {
        return "100%"
      }
    },

    detailsWidth() {
      return this.cardDisplay === HORIZONTAL &&
        (this.showImage || this.isLoading)
        ? `${100 - parseInt("30%")}%`
        : "100%"
    },

    showPlayButton() {
      return (
        isPresent(this.video) &&
        isEmpty(this.image) &&
        this.cardDisplay === "vertical"
      )
    },

    showPreTitle() {
      return this.isEventOccurrence || this.hasDate
    },

    showBookedBadge() {
      return this.showBooked && this.hasDate
    },

    showFooter() {
      return this.isEventOccurrence
    },

    isEventOccurrence() {
      return this.type === "event_occurrence" && this.itemData
    },

    isDateCardVariant() {
      return this.modifiers && this.modifiers.includes("date-card")
    },

    isGenericPage() {
      return (
        (this.isPage && isEmpty(this.contentType)) ||
        this.contentType === "page"
      )
    },

    isPage() {
      return this.type === "page"
    },

    isVerticalCard() {
      return this.cardDisplay === "vertical"
    },

    isHorizontalCard() {
      return this.cardDisplay === "horizontal"
    },

    isRaised() {
      return this.cardStyle === "raised"
    },

    hasDate() {
      return isPresent(this.schedule?.starts_at)
    },

    itemImage() {
      return isPresent(this.image)
        ? getImageUrl(this.image)
        : isPresent(this.video) && isPresent(this.video.thumbnail_url)
        ? this.video.thumbnail_url
        : null
    },

    showImage() {
      return this.isPage && !this.modifiers
        ? isPresent(this.itemImage)
        : !this.modifiers || !this.modifiers.includes("no-image")
    },

    shouldShowCountdown() {
      return (
        this.showCountdown &&
        this.showTime &&
        !(this.isLocked || this.isLoadingAccess || this.isLoading) &&
        this.hasDate &&
        this.schedule &&
        this.schedule.starts_at &&
        !this.schedule.is_all_day
      )
    },

    shouldFetchPageData() {
      return (
        this.hasCheckedAuthentication &&
        isPresent(this.pageId) &&
        !this.isCompletable &&
        this.isProgressRoot &&
        this.showProgress &&
        !isPresent(this.pageProgress)
      )
    },

    isCompletable() {
      return this.isPage && this.itemData.is_completable
    },

    isProgressRoot() {
      return !!this.itemData?.is_progress_root
    },

    isPageCompleted() {
      return isPresent(this.pageId) && !this.isLocked
        ? this.isPageComplete(this.pageId)
        : false
    },

    pageProgress() {
      return isPresent(this.pageId) ? this.getPageProgress(this.pageId) : null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.collection-item {
  @include clickable;

  height: 100%;

  opacity: 0;
  animation: fade-in 0.2s ease forwards;

  &.--vertical {
    .collection-item__content {
      width: 100%;
      height: 100%;
    }
  }

  &.--horizontal {
    > .v-link {
      display: flex;
    }

    .collection-item__content {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .collection-item__image-wrapper {
      width: 30%;
      height: 100%;
      flex-shrink: 0;
    }

    .collection-item__details {
      padding: $size--2 $size--2 $size--2 $size--4;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  &.--raised {
    .collection-item__content {
      background: $color-ui--white;
      border-radius: var(--border-radius);
      box-shadow: var(--card-shadow);
      overflow: hidden;
    }

    &.--horizontal {
      .collection-item__image-wrapper {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        min-height: 100%;
      }
    }

    &.--vertical {
      .collection-item__details {
        padding: $size--3;
      }

      .collection-item__image-wrapper {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &.--no-uppercase {
    .collection-item__details {
      .collection-item__type {
        text-transform: none;
      }
    }
  }

  &.--event {
    .collection-item__details {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .collection-item__price {
      margin-left: auto;
    }
  }

  &:last-child {
    .collection-item__content {
      margin-bottom: 0;
    }
  }

  &.--schedule-event {
    .v-link {
      position: relative;
    }

    &.--with-ancestors.--raised {
      border-radius: var(--border-radius);
      box-shadow: var(--card-shadow);
    }

    .collection-item__ancestors {
      border-top: 1px solid $color-ui--grey-20;
      display: none;

      .collection-item {
        &.--raised {
          border-radius: 0;
          box-shadow: none;
        }
      }

      .collection-item__pretitle {
        margin-bottom: 0;
      }

      .collection-item__title {
        font-size: $font-size--small;
      }

      .collection-item__subtitle {
        display: none;
      }

      > .collection-item.--horizontal .collection-item__content {
        min-height: 2rem;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }

      .collection-item__image-wrapper {
        display: none;
      }
    }

    &.--with-ancestors {
      > .collection-item__ancestors {
        display: block;
      }

      > .v-link > .collection-item__content {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;

        > .collection-item__image-wrapper {
          border-radius: 0;
        }
      }

      .collection-item__content {
        box-shadow: none !important;
      }
    }
  }
}

.collection-item__content {
  min-height: $size--10;
}

.collection-item__details {
  padding: $size--3 $size--2;
  width: 100%;
  text-align: left;
}

.collection-item__price {
  background: $color-ui--grey-50;
  border-radius: var(--border-radius);
  font-size: $font-size--x-small;
  font-weight: $font-weight--bold;
  color: $color-ui--white;
  padding: $size--1 $size--2;
  align-self: flex-start;
  justify-self: flex-end;
  margin-left: $size--2;
  margin-top: 2px;
}

.collection-item__price + .collection-item__book {
  margin-top: 0.5rem;
}

.collection-item__pretitle {
  font-size: $font-size--x-small;
  color: $color-ui--grey-50;
  font-weight: $font-weight--bold;
  margin-bottom: $size--1;
}

.collection-item__title {
  font-size: $size--4;
  line-height: 1.3rem;
  font-weight: $font-weight--semi-bold;
  margin: 0;
  color: $color-fixed--grey-90;
}

.collection-item {
  &.--invert-colors:not(.--raised) {
    .collection-item__title {
      color: $color-fixed--white;
    }
  }
}

.collection-item__subtitle {
  font-size: $font-size--small;
  color: $color-ui--grey-60;
  font-weight: 500;
  margin: 0;
}

.collection-item__type {
  @include overflow-ellipsis;

  display: block;
  margin: $size--1 0 0.125rem;
  font-size: 0.7rem;
  color: $color-ui--grey-50;
}

.collection-item__footer {
  color: $color-ui--grey-50;
  font-size: $font-size--x-small;
  display: flex;
  font-weight: $font-weight--bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .v-icon {
    font-size: 1.2em;
    margin-right: 5px;
  }
}

.collection-item__image-wrapper {
  background: $color-ui--grey-20;
  border-radius: 5px;
  width: 100%;
  height: 180px;
  overflow: hidden;
  position: relative;
}

.collection-item__image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}
</style>

<style lang="scss">
@import "@/assets/styles/variables";

.collection-item {
  &.--no-image.--vertical,
  &.--horizontal {
    .countdown {
      position: relative;
      top: 0;
      bottom: auto;
      left: 0;
      display: inline-block;
      height: auto;
      padding: $size--1 $size--3;
      margin-top: 0;
      margin-left: -0.1rem;
      margin-top: -0.1rem;
      margin-bottom: $size--2;
      max-width: 100%;
    }
  }

  &.--no-image.--invert-colors:not(.--raised),
  &.--horizontal.--invert-colors:not(.--raised) {
    .countdown {
      background-color: rgba($color-rgb--grey-30, 0.16);
    }
  }

  .completion-percent {
    position: absolute;
    bottom: 10px;
    right: -$size--1;
    border-radius: $size--10;
    flex-direction: row-reverse;
    animation: fade-in 0.4s ease;

    ons-progress-circular {
      height: $size--6;
      width: $size--6;
    }

    .progress-circular--material__primary,
    .progress-circular--material__secondary {
      stroke-width: 3px;
    }

    .completion-percent__text {
      margin-right: $size--1;
    }
  }

  &:not(.--raised).--vertical {
    .completion-percent {
      bottom: 24px;
      right: -$size--2;
    }
  }
}

.--dark-mode {
  .collection-item {
    &.--raised {
      .collection-item__content {
        background: $color-ui--grey-15;
      }
    }
  }
}
</style>
