<template>
  <div class="form-field">
    <div
      v-if="field.label || field.description || isMissable"
      class="d-flex p-relative"
    >
      <div v-if="field.label || field.description">
        <label
          v-if="field.label"
          :for="fieldHtmlId(field.id)"
          class="form-field__label font-weight-semi-bold d-flex align-items-center"
          data-cy="form-label"
        >
          {{ field.label }}
          <small v-if="!field.is_required" class="text-grey-50 ml-1">
            -&nbsp;{{ $t("components.formField.optional") }}
          </small>
        </label>

        <div
          v-if="field.description"
          class="text-x-small text-grey-60 font-weight-semi-bold form-field__description"
          data-cy="form-description"
        >
          {{ field.description }}
        </div>
      </div>

      <div v-if="isMissable" class="form-group__miss">
        <v-button v-if="isMissed" primary small @click="$emit('miss', false)">
          <v-icon icon="cancel" />
          {{ $t("components.formField.missed") }}
        </v-button>

        <v-button v-else ghost small secondary @click="$emit('miss', true)">
          <v-icon icon="cancel" />
          {{ $t("components.formField.miss") }}
        </v-button>
      </div>
    </div>

    <template v-if="field.field_type === FIELD_TYPES.ACCEPTANCE">
      <div class="form-field__input">
        <div
          data-cy="form-body"
          class="form-field__accept-body mb-2"
          v-html="field.body"
        />
        <div class="form-group">
          <v-switch
            :value="value.value"
            @input="value => handleUpdate({ value })"
          >
            <span class="font-weight-semi-bold">{{
              $t("components.formField.accept")
            }}</span>
          </v-switch>
        </div>
      </div>
    </template>

    <template v-else-if="field.field_type === FIELD_TYPES.SHORT_ANSWER">
      <div class="form-field__input">
        <v-input
          type="text"
          data-cy="form-input"
          :id="fieldHtmlId(field.id)"
          :value="isMissable && isMissed ? field.default_value : value.value"
          :disabled="isMissable && isMissed"
          @input="value => handleUpdate({ value })"
        />
      </div>
    </template>

    <template v-else-if="field.field_type === FIELD_TYPES.NUMBER">
      <div class="form-field__input">
        <v-input
          type="number"
          data-cy="form-input"
          :id="fieldHtmlId(field.id)"
          :value="isMissable && isMissed ? field.default_value : value.value"
          :disabled="isMissable && isMissed"
          @input="value => handleUpdate({ value })"
        >
          <template v-if="field.value_type" v-slot:append>
            {{ field.value_type }}
          </template>
        </v-input>
      </div>
    </template>

    <template v-else-if="field.field_type === FIELD_TYPES.LONG_ANSWER">
      <div class="form-field__input">
        <v-textarea
          data-cy="form-input"
          :id="fieldHtmlId(field.id)"
          :value="value.value"
          @input="value => handleUpdate({ value })"
        />
      </div>
    </template>

    <template v-else-if="field.field_type === FIELD_TYPES.MULTI_SELECT">
      <div class="form-field__input">
        <v-multi-radio
          :with-filter="(field.options || []).length > MULTI_SELECT_LIMIT"
          :options="field.options || []"
          :current-value="value.value"
          :allow-multiple="field.allow_multiple"
          class="form-group mb-3"
          @change="value => handleUpdate({ value })"
        />
      </div>
    </template>

    <template v-else-if="field.field_type === FIELD_TYPES.IMAGE_UPLOAD">
      <v-image-input
        :value="isSubscriberProperty ? value.value : value.image"
        class="mt-1"
        @upload="handleUpload"
        @remove="removeImage"
      />
    </template>

    <template v-else-if="field.field_type === FIELD_TYPES.DATE">
      <div class="form-field__input">
        <v-date-input
          :id="fieldHtmlId(field.id)"
          :value="value.value"
          @input="value => handleUpdate({ value })"
        />
      </div>
    </template>

    <div
      v-if="isShownOnMemberProfile"
      class="form-field__footer d-flex align-items-center"
    >
      <v-icon icon="public" size="1rem" />
      {{ $t("components.formField.publicProfileWarning") }}
    </div>
  </div>
</template>

<script>
import { FIELD_TYPES } from "@/lib/form-utils"

import vSwitch from "@/components/forms/vSwitch"
import vInput from "@/components/forms/vInput"
import vTextarea from "@/components/forms/vTextarea"
import vImageInput from "@/components/forms/vImageInput"
import vDateInput from "@/components/forms/vDateInput"
import vMultiRadio from "@/components/forms/vMultiRadio"

export default {
  props: {
    value: { type: Object, required: true },
    field: { type: Object, required: true },
    isSubscriberProperty: { type: Boolean, default: false },
    isMissed: { type: Boolean, default: false },
    isMissable: { type: Boolean, default: false }
  },

  components: {
    vSwitch,
    vInput,
    vTextarea,
    vImageInput,
    vDateInput,
    vMultiRadio
  },

  data() {
    return {
      MULTI_SELECT_LIMIT: 10,
      FIELD_TYPES
    }
  },

  computed: {
    isShownOnMemberProfile() {
      return !!this.field?.show_on_member_profile
    }
  },

  methods: {
    fieldHtmlId(fieldId) {
      return `form-field-id-${fieldId}`
    },

    removeImage() {
      this.handleUpdate({ image: null, value: null })
    },

    handleUpload(blob) {
      this.handleUpdate({ image: blob.signed_id, value: blob.path })
    },

    handleUpdate(value) {
      this.$emit("input", {
        ...this.value,
        ...value
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.form-field__footer {
  margin-top: $size--1;
  font-size: $font-size--x-small;
  color: $color-ui--grey-90;
  font-weight: 500;

  .v-icon {
    color: var(--primary-brand-color-hex);
    margin-right: $size--1;
  }
}
</style>

<style lang="scss">
@import "@/assets/styles/variables";

.form-field__input {
  position: relative;
  margin-top: $size--3;
}

.form-field__accept-body {
  font-size: $font-size--small;
  padding: $size--1 $size--4 !important;
  border: 2px solid $color-ui--grey-20;
  border-radius: var(--border-radius);
  max-height: 50vh;
  overflow: auto;
}

.form-field__description {
  margin: $size--1 0;
  white-space: pre-line;
}

.page--material__content {
  .form-field__accept-body {
    p {
      margin: 0.5rem 0;
    }
  }
}
</style>
