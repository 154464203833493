import { LIGHT_BOX_KEY } from "@/lib/constants"

/**
 * MK Lightbox
 *
 * Copied from: https://github.com/marcuskirschen/mk-lightbox/blob/master/mklb/js/mklb.js with some adjustments
 */
const svgIcons = {
  close:
    '<div class="mk-lightbox__button mklbClose"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/></svg></div>',
  next:
    '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"/></g></svg>',
  prev:
    '<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"/></g></svg>',
  pause:
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"/></svg>',
  download:
    '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><g><rect fill="none" height="24" width="24"/></g><g><path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z"/></g></svg>'
}

const ELEMENT_IDS = {
  DOWNLOAD: "download",
  DOWNLOAD_CONTAINER: "downloadContainer",
  NEXT: "next",
  NEXT_CONTAINER: "nextContainer",
  PREV: "prev",
  PREV_CONTAINER: "prevContainer",
  LIGHTBOX_CONTAINER: "mkLightboxContainer",
  OVERLAY: "overlay",
  IMAGE: "mklbImage",
  INNER: "mklbInner"
}

const pinchToZoomAttribute = "maximum-scale=1"
const getViewportAttributes = () =>
  document
    .querySelector("meta[name=viewport]")
    .getAttribute("content")
    .split(",")
    .map(attr => attr.trim())

const addMaxScaleMetaTag = () =>
  getViewportAttributes().includes(pinchToZoomAttribute) ||
  document.querySelector("meta[name=viewport]").setAttribute(
    "content",
    getViewportAttributes()
      .concat(pinchToZoomAttribute)
      .join(",")
  )

const removeMaxScaleMetaTag = () =>
  getViewportAttributes().includes(pinchToZoomAttribute) &&
  document.querySelector("meta[name=viewport]").setAttribute(
    "content",
    getViewportAttributes()
      .filter(attr => attr !== pinchToZoomAttribute)
      .join(",")
  )

const toggleNextArrows = () => {
  const imageCount = document.getElementsByClassName("imageContainer").length
  const inner = document.getElementById(ELEMENT_IDS.INNER)

  if (!inner) return

  const offset =
    (Math.abs(parseInt(inner.style.marginLeft.slice(0, -2))) + 100) / 100

  if (offset === 1) {
    document.getElementById("prevContainer").classList.remove("--show")
  } else {
    document.getElementById("prevContainer").classList.add("--show")
  }

  if (offset === imageCount) {
    document.getElementById("nextContainer").classList.remove("--show")
  } else {
    document.getElementById("nextContainer").classList.add("--show")
  }
}

const getCurrentIndex = () => {
  const inner = document.getElementById(ELEMENT_IDS.INNER)

  let marginLeft = inner.style.marginLeft
  marginLeft = parseInt(marginLeft.slice(0, marginLeft.length - 2))

  return Math.abs(marginLeft / 100)
}

const getCurrentImage = () =>
  document
    .getElementsByClassName("imageContainer")
    [getCurrentIndex()]?.querySelector("img")

const updateDownloadLink = () => {
  const downloadEl = document.getElementById(ELEMENT_IDS.DOWNLOAD)
  const image = getCurrentImage()

  if (!image) return

  downloadEl.href = image.src
}

// https://stackoverflow.com/a/56663695/19403291
let isMultiTouch = false
let touchstartX = 0
let touchendX = 0
let touchstartY = 0
let touchendY = 0

const addSwipeListener = (
  element,
  leftCallback,
  rightCallback,
  dismissCallback
) => {
  element.addEventListener("touchstart", e => {
    isMultiTouch = e.touches.length > 1

    if (isMultiTouch) return

    // Prevents swipe when zoomed in
    if (window.visualViewport.scale !== 1) return

    touchstartX = e.changedTouches[0].screenX
    touchstartY = e.changedTouches[0].screenY
  })

  element.addEventListener("touchend", e => {
    if (isMultiTouch) return

    // Prevents swipe when zoomed in
    if (window.visualViewport.scale !== 1) return

    touchendX = e.changedTouches[0].screenX
    touchendY = e.changedTouches[0].screenY

    if (touchendY - 75 > touchstartY) dismissCallback()
    if (touchendX < touchstartX) leftCallback()
    if (touchendX > touchstartX) rightCallback()
  })
}

export default function openImage({
  element,
  imageId,
  groupId = LIGHT_BOX_KEY
}) {
  const mklbItems = document.querySelectorAll(
    `[data-lightbox-group=${groupId}]`
  )
  const mklbItem = element || document.getElementById(imageId)

  if (!mklbItem) return

  const lightboxContainer = document.createElement("div")
  lightboxContainer.id = ELEMENT_IDS.LIGHTBOX_CONTAINER

  const overlay = document.createElement("div")
  overlay.id = ELEMENT_IDS.OVERLAY
  lightboxContainer.appendChild(overlay)

  // Allows pinch to zoom on mobile
  removeMaxScaleMetaTag()

  if ("gallery" in mklbItem.dataset) {
    _mklbAddGallery(mklbItem)
  } else {
    lightboxContainer.appendChild(_mklbAddImage(mklbItem))
  }

  const closeIconContainer = document.createElement("div")
  closeIconContainer.id = "closeIconContainer"
  closeIconContainer.innerHTML = svgIcons.close
  lightboxContainer.appendChild(closeIconContainer)

  closeIconContainer.addEventListener("click", _closeLightbox)

  document.body.appendChild(lightboxContainer)
  overlay.addEventListener("click", _closeLightbox)

  function _mklbAddImage(item) {
    const image = document.createElement("img")
    image.id = ELEMENT_IDS.IMAGE
    image.src = "src" in item.dataset ? item.dataset.src : item.src

    return image
  }

  function _mklbAddGallery(currentItem) {
    const gallery = []
    let index = 0

    const mklbInner = document.createElement("div")
    mklbInner.id = ELEMENT_IDS.INNER

    for (let i = 0; i < mklbItems.length; i++) {
      if (
        "gallery" in mklbItems[i].dataset &&
        mklbItems[i].dataset.gallery === currentItem.dataset.gallery
      ) {
        gallery.push(mklbItems[i])

        if (mklbItems[i] === currentItem) {
          index = gallery.length
        }
        const imageContainer = document.createElement("section")
        imageContainer.className = "imageContainer"

        imageContainer.addEventListener("click", _closeLightbox)

        imageContainer.appendChild(_mklbAddImage(mklbItems[i]))
        mklbInner.appendChild(imageContainer)
      }
    }

    mklbInner.style.marginLeft = (index - 1) * -100 + "vw"
    lightboxContainer.appendChild(mklbInner)

    const prev = document.createElement("div")
    prev.id = ELEMENT_IDS.PREV
    prev.innerHTML = svgIcons.prev
    const prevContainer = document.createElement("div")
    prevContainer.id = ELEMENT_IDS.PREV_CONTAINER
    prevContainer.appendChild(prev)
    lightboxContainer.appendChild(prevContainer)
    prevContainer.addEventListener("click", () => _mklbSlide(true))

    const next = document.createElement("div")
    next.id = ELEMENT_IDS.NEXT
    next.setAttribute("data-next", index <= gallery.length ? index + 1 : 1)
    next.innerHTML = svgIcons.next
    const nextContainer = document.createElement("div")
    nextContainer.id = ELEMENT_IDS.NEXT_CONTAINER
    nextContainer.appendChild(next)
    lightboxContainer.appendChild(nextContainer)
    nextContainer.addEventListener("click", function() {
      _mklbSlide(false)
    })

    const download = document.createElement("a")
    download.id = ELEMENT_IDS.DOWNLOAD
    download.classList.add("mk-lightbox__button")
    download.href =
      "src" in currentItem.dataset ? currentItem.dataset.src : currentItem.src
    download.target = "_blank"
    download.innerHTML = svgIcons.download
    const downloadContainer = document.createElement("div")
    downloadContainer.id = ELEMENT_IDS.DOWNLOAD_CONTAINER
    downloadContainer.appendChild(download)
    lightboxContainer.appendChild(downloadContainer)

    setTimeout(() => {
      addSwipeListener(
        mklbInner,
        () => _mklbSlide(false),
        () => _mklbSlide(true),
        _closeLightbox
      )

      toggleNextArrows()
    }, 50)
  }

  function _closeLightbox() {
    // Reverts to default zoom on mobile
    addMaxScaleMetaTag()
    document
      .getElementById(ELEMENT_IDS.LIGHTBOX_CONTAINER)
      .classList.add("--closing")
    setTimeout(() => {
      document.getElementById(ELEMENT_IDS.LIGHTBOX_CONTAINER).remove()
    }, 300)
  }

  function _mklbSlide(slideToPrev) {
    const inner = document.getElementById(ELEMENT_IDS.INNER)
    const elements = document.getElementsByClassName("imageContainer").length
    let marginLeft = inner.style.marginLeft
    marginLeft = parseInt(marginLeft.slice(0, marginLeft.length - 2))

    if (slideToPrev && marginLeft === 0) {
      // Don't allow sliding past end of gallery
    } else if (slideToPrev) {
      inner.style.marginLeft = marginLeft + 100 + "vw"
    } else if (marginLeft === (elements - 1) * -100) {
      // Prevent sliding before start of gallery
    } else {
      inner.style.marginLeft = marginLeft - 100 + "vw"
    }

    updateDownloadLink()
    toggleNextArrows()
  }
}

export const generateImageKeyForMessage = (messageTs, index = "0") => {
  return `mklbItem-${messageTs}-${index}`
}
