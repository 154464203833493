<template>
  <div
    :key="block.uuid"
    ref="block"
    :data-block="block.uuid"
    :data-role="ROLES.BLOCK"
    :data-type="block.type"
    :class="[block.bg_color ? `--bg-${block.bg_color}` : null, 'block']"
  >
    <template v-if="block.type === BLOCK_TYPES.HERO_BLOCK">
      <hero
        :block-action="block.data.block_action"
        :video-finished-action="block.data.video_finished_action"
        :image="blockImage(block)"
        :video="blockVideo(block)"
        :audio="blockAudio(block)"
        :video-options="{
          playsInline: block.data.video_plays_inline,
          autoInitialize: true,
          autoplay: block.data.video_is_autoplay,
          hideControls: block.data.video_hides_controls,
          loop: block.data.video_is_loop,
          cover: block.data.video_fit === 'cover'
        }"
        :audio-options="{
          loop: block.data.audio_is_loop,
          white: !!blockImage(block) || shouldInvertColor
        }"
        :overlay-shadow="block.data.is_overlaid"
        :image-opacity-ratio="parseIntIfGiven(block.data.image_opacity_ratio)"
        :background-color="block.data.background_color"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
        :style-options="{
          size: block.data.background_size,
          width: block.data.background_width,
          heightRatio: block.data.height_ratio,
          heightRatioUnit: block.data.height_ratio_unit,
          heightScaleRatio: block.data.height_scale_ratio,
          borderRadiusRatio: block.data.border_radius_ratio,
          textPositionX: block.data.text_position_x,
          textPositionY: block.data.text_position_y,
          imagePositionX: block.data.image_position_x,
          imagePositionY: block.data.image_position_y
        }"
      >
        <template v-slot="{ height }">
          <blocks-container
            :style="`min-height: ${height}`"
            class="--light-mode"
            :blocks="block.blocks"
            :page-id="pageId"
            :parent-block="block"
            :unlock="unlock"
            :ignore-blocks="ignoreBlocks"
          />
        </template>
      </hero>
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.ATTACHMENT_BLOCK">
      <attachment-block
        :page-id="pageId"
        :image="blockImage(block)"
        :attachment="blockAttachment(block)"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
        :height-ratio="parseIntIfGiven(block.data.height_ratio)"
        :card-style="block.data.card_style"
        :unlock="unlock"
      >
        <template v-slot="{ height }">
          <blocks-container
            :style="`min-height: ${height}`"
            :blocks="block.blocks"
            :page-id="pageId"
            :parent-block="block"
            :unlock="unlock"
            :ignore-blocks="ignoreBlocks"
          />
        </template>
      </attachment-block>
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.IFRAME_BLOCK">
      <iframe-block
        :url="block.data.url"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
        :height-ratio="parseIntIfGiven(block.data.height_ratio)"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.TEXT_BLOCK">
      <text-block
        :uuid="block.uuid"
        :content="block.data.content"
        :page-attribute-slug="block.data.page_attribute_slug"
        :dynamic-content="block.data.dynamic_content"
        :size-ratio="parseIntIfGiven(block.data.size_ratio)"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
        :tag="block.data.tag"
        :align="block.data.align"
        :color="block.data.color"
        :page-id="pageId"
        :collapsable="block.data.is_collapsable"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.COLLECTION_BLOCK">
      <collection
        data-autodetect-color
        :path="block.data.collection ? block.data.collection.path : null"
        :items="blockItems(block)"
        :page-id="pageId"
        :card-style="block.data.card_style"
        :card-display="block.data.card_display"
        :direction="block.data.direction"
        :columns="parseIntIfGiven(block.data.columns)"
        :rows="parseIntIfGiven(block.data.rows)"
        :column-width-ratio="parseIntIfGiven(block.data.column_width_ratio)"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
        :card-image-size="parseIntIfGiven(block.data.image_size_ratio)"
        :modifiers="block.data.image_shown ? null : 'no-image'"
        :show-price="block.data.show_price"
        :show-time="block.data.show_time"
        :show-progress="block.data.show_progress"
        :show-booked="block.data.show_booked"
        :unlock="unlock"
        :collection="block.data.collection"
        :invert-colors="shouldInvertColor"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.BUTTON_BLOCK">
      <button-block
        :block-action="block.data.block_action"
        :text="block.data.text"
        :post-purchase-text="block.data.post_purchase_text"
        :display="block.data.display"
        :size="block.data.size"
        :btn-style="block.data.style"
        :variant="block.data.variant"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.CHAT_BLOCK">
      <chat-block
        :message-thread-id="block.data.message_thread_id"
        :is-comment-thread="block.data.is_comment_thread"
        :is-feed="block.data.is_feed"
        :page-id="pageId"
        :channel-id="block.data.channel_id"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.LIVESTREAM_BLOCK">
      <livestream-block
        :id="block.data.livestream.id"
        :provider="block.data.livestream.provider"
        :remote-id="block.data.livestream.remote_id"
        :message-thread-id="block.data.livestream.message_thread_id"
        :video="block.data.livestream.video"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.AVATAR_BLOCK">
      <avatar-block />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.IMAGE_BLOCK">
      <image-block
        :image="block.data.image"
        :align="block.data.align"
        :width-ratio="parseIntIfGiven(block.data.width_ratio)"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.SHARE_BLOCK">
      <share-block
        data-autodetect-color
        :url="block.data.url"
        :title="block.data.title"
        :white="shouldInvertColor"
        :visible-platforms="Object.keys(block.data).filter(n => block.data[n])"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.WORKOUT_BLOCK">
      <workout-block
        data-autodetect-color
        :invert-colors="shouldInvertColor"
        :page-id="pageId"
        :workout="block.data.workout"
        :block-action="block.data.block_action"
        :workout-mode-override="block.data.workout_mode"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.ICON_LIST_BLOCK">
      <icon-list-block
        :icons="block.data.icon_list"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
        :text-color="block.data.text_color"
        :size-ratio="block.data.size_ratio"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.MAP_BLOCK">
      <map-block
        :map="block.data"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
        :height-ratio="parseIntIfGiven(block.data.height_ratio)"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.COMPOSED_BLOCK">
      <composed-block
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
      >
        <template v-slot="{ padding }">
          <blocks-container
            :style="{ padding }"
            :blocks="block.blocks"
            :page-id="pageId"
            :parent-block="block"
            :unlock="unlock"
            :ignore-blocks="ignoreBlocks"
          />
        </template>
      </composed-block>
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.FORM_SUBMISSION_BLOCK">
      <form-submission-block
        :block-id="block.data.id"
        :channel-id="block.data.channel_id"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.SEARCH_BLOCK">
      <search-block
        :page-id="pageId"
        :content-types="block.data.page_content_types || []"
        :with-tags="block.data.search_tags || []"
        :display-tags="block.data.display_tags || []"
        :with-display-tags="block.data.with_display_tags"
        :with-all-tags="!!block.data.with_all_tags"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.CODE_BLOCK">
      <code-block
        :code="block.data.code"
        :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
        :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
        :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
      />
    </template>

    <template v-else-if="block.type === BLOCK_TYPES.SOCIAL_BLOCK">
      <div data-autodetect-color>
        <social-block
          :data="block.data"
          :white="shouldInvertColor"
          :space-x-ratio="parseIntIfGiven(block.space_x_ratio)"
          :space-top-ratio="parseIntIfGiven(block.space_top_ratio)"
          :space-bottom-ratio="parseIntIfGiven(block.space_bottom_ratio)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

import {
  buildBlockMountedEvent,
  getBlockImage,
  getBlockVideo,
  getBlockAudio,
  getBlockItems,
  BLOCK_TYPES,
  ROLES
} from "@/lib/stream-utils"
import { isPresent } from "@/lib/utils"
import EventBus, { EVENTS } from "@/lib/event-bus"
import {
  isDarkTextColor,
  rgbToHex,
  APP_COLOR_HEX
} from "@/lib/determine-text-color"
import debounce from "@/lib/debounce"

import Hero from "@/components/Hero"
import TextBlock from "@/components/TextBlock"
import Collection from "@/components/Collection"
import ButtonBlock from "@/components/ButtonBlock"

export default {
  components: {
    Hero,
    TextBlock,
    Collection,
    ButtonBlock,
    BlocksContainer: () => import("@/components/stream/BlocksContainer"),
    AvatarBlock: () => import("@/components/AvatarBlock"),
    AttachmentBlock: () => import("@/components/AttachmentBlock"),
    IframeBlock: () => import("@/components/blocks/IframeBlock"),
    ChatBlock: () => import("@/components/blocks/ChatBlock"),
    LivestreamBlock: () => import("@/components/blocks/LivestreamBlock"),
    ImageBlock: () => import("@/components/blocks/ImageBlock"),
    ShareBlock: () => import("@/components/blocks/ShareBlock"),
    WorkoutBlock: () => import("@/components/blocks/WorkoutBlock"),
    IconListBlock: () => import("@/components/blocks/IconListBlock"),
    MapBlock: () => import("@/components/blocks/MapBlock"),
    ComposedBlock: () => import("@/components/blocks/ComposedBlock"),
    FormSubmissionBlock: () =>
      import("@/components/blocks/FormSubmissionBlock"),
    SearchBlock: () => import("@/components/blocks/SearchBlock"),
    CodeBlock: () => import("@/components/blocks/CodeBlock"),
    SocialBlock: () => import("@/components/blocks/SocialBlock")
  },

  props: {
    block: { type: Object, required: true },
    parentBlock: { type: Object, default: () => ({}) },
    index: { type: Number, default: 1 },
    unlock: { type: Boolean, default: null },
    pageId: { type: [String, Number], default: null },
    ignoreBlocks: { type: Array, default: () => [] }
  },

  data() {
    return {
      shouldInvertColor: false,
      BLOCK_TYPES,
      ROLES
    }
  },

  inject: {
    $references: {
      default: () => {}
    }
  },

  computed: {
    ...mapState(["global"]),
    ...mapGetters("pages", ["getPageAttributes"]),

    watchedTheme() {
      return JSON.stringify(this.global.theme)
    }
  },

  mounted() {
    this.detectInvertColor()

    // TODO: Lazy load if in builder?
    EventBus.$emit(
      EVENTS.BLOCK_MOUNTED,
      buildBlockMountedEvent(() => this.block, this.$el)
    )
  },

  watch: {
    block() {
      this.debouncedDetectInvertColor()
    },

    watchedTheme() {
      this.debouncedDetectInvertColor()
    }
  },

  methods: {
    isPresent,

    blockImage(block) {
      return getBlockImage(block, this.getPageAttributes(this.pageId))
    },

    blockVideo(block) {
      return getBlockVideo(block, this.getPageAttributes(this.pageId))
    },

    blockAudio(block) {
      return getBlockAudio(block, this.getPageAttributes(this.pageId))
    },

    blockAttachment(block) {
      return isPresent(block.data.attachment)
        ? block.data.attachment
        : undefined
    },

    blockItems(block) {
      return getBlockItems(block, this.$references ? this.$references() : {})
    },

    parseIntIfGiven(value) {
      return value || value === 0 ? parseInt(value) : undefined
    },

    detectInvertColor() {
      this.$nextTick(() => {
        if (!this.$refs.block) return

        const detectedElement = this.$refs.block.querySelector(
          "[data-autodetect-color]"
        )

        if (!detectedElement) return

        const style = getComputedStyle(detectedElement)

        this.shouldInvertColor = isDarkTextColor(
          rgbToHex(style.getPropertyValue("color")) || APP_COLOR_HEX.BLACK
        )
      })
    },

    debouncedDetectInvertColor() {
      debounce(this.detectInvertColor, 20)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.block {
  position: relative;

  @include app-background;
}
</style>
