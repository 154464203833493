import { PageAccess, GlobalAppAccess, Product } from "@shared/types"
import {
  PageId,
  AccessTreeAccess,
  AccessTreeAccessRoot,
  User,
  PageProgress,
  PageProgresses
} from "@/types"

import store from "@/store"
import { isPresent } from "@/lib/utils"
import { add, isFuture } from "@/lib/date-utils"

// Must match backend/app/models/page/access.rb
export const REASONS = {
  REQUIRE_PURCHASE: "PURCHASE_REQUIRED",
  REQUIRE_AUTHENTICATION: "AUTHENTICATION_REQUIRED",
  IS_PRIVATE: "IS_PRIVATE",
  IS_SCHEDULED: "IS_SCHEDULED",
  VIEWABLE_CHILDREN: "VIEWABLE_CHILDREN",
  START_REQUIRED: "START_REQUIRED",
  ALLOW_BROWSING: "ALLOW_BROWSING"
} as const

// Must match backend/app/models/page/access.rb
export const LEGACY_ACCESS = "INHERIT"

export const shouldDisableModalClose = (
  appAccess: GlobalAppAccess,
  currentPageId: string | number
) => {
  const appDisallowsBrowsing =
    !appAccess.allow_anonymous_browsing &&
    (appAccess.require_sign_in || appAccess.is_subscriber_only)

  const currentPageAccess = getPageAccess({
    pageId: currentPageId,
    appAccess,
    currentPageId
  })

  return appDisallowsBrowsing && !currentPageAccess.granted
}

type RequirePurchaseAccessResponse = {
  granted: false
  reason: typeof REASONS.REQUIRE_PURCHASE
  data: {
    product_ids: number[]
    purchase_overlay?: number
  }
}

type RequireAuthenticationAccessResponse = {
  granted: false
  reason: typeof REASONS.REQUIRE_AUTHENTICATION
}

type IsPrivateAccessResponse = {
  granted: false
  reason: typeof REASONS.IS_PRIVATE
}

type IsDrippedAccessResponse =
  | {
      granted: false
      reason: typeof REASONS.IS_SCHEDULED
    }
  | {
      granted: false
      reason: typeof REASONS.IS_SCHEDULED
      data: { available_after: string | Date }
    }

type GrantedAccessResponse = {
  granted: true
}

/**
 * Takes a Page ID and traverses the access tree until it finds a parent with is_progress_root = true
 */
export const findProgressRoot = (
  pageId: string | number
): string | number | null => {
  const access: AccessTreeAccess = store.getters["pages/getPageAccess"](pageId)

  if (!access) return null

  if ("is_progress_root" in access && access.is_progress_root) return pageId

  if (access.parent) {
    return findProgressRoot(access.parent)
  }

  return null
}
/**
 * Takes a Page ID and traverses the access tree until it finds the access root, or exhausts
 * all possible paths.
 *
 * @returns {Array} [The access definition OR null, Page ID of the access root OR the last node checked, Whether
 * the tree was switched during the lookup]
 */
export const findAccessRoot = (
  pageId: PageId | null,
  currentPageId: string | number | null,
  hasSwitchedTree = false,
  _foundInOriginalTree = false
): [AccessTreeAccessRoot | null, string | number | null, boolean] => {
  // If the page is found in the "original" tree (i.e. it has an access root, or is a decendant of
  // the current page) then we should not switch trees when looking for the access root.
  // This prevents unnecessary lookups and also provides the caller a way to know if the page
  // was found in the original tree (which is used by global access for inheriting pages)
  const foundInOriginalTree =
    pageId === currentPageId ? !hasSwitchedTree : _foundInOriginalTree

  if (!pageId) {
    return [null, pageId, hasSwitchedTree]
  }

  const access: AccessTreeAccess = store.getters["pages/getPageAccess"](pageId)

  if (!access) {
    if (currentPageId && !hasSwitchedTree) {
      // This is the case for legacy content types
      // The access definition is not found in the access tree, but if we are given
      // a currentPageId then we treat legacy content as { inherit: true } and therefore
      // should look up the access root from the current tree
      return findAccessRoot(
        currentPageId,
        currentPageId,
        true,
        foundInOriginalTree
      )
    } else {
      return [null, pageId, hasSwitchedTree]
    }
  }

  if (access.inherit === false) return [access, pageId, hasSwitchedTree]

  if (!access.parent) {
    if (hasSwitchedTree || foundInOriginalTree) {
      return [null, pageId, hasSwitchedTree]
    } else if (currentPageId) {
      // "Switching Trees" is the idea that we are switching which page tree we are looking
      // for the access root in. This is how we handle the case where a page, and all its
      // ancestors are set to "inherit" and we need to then check the current page's access tree
      // before falling back to the global access rules
      //
      // E.g.
      //
      // - Program           - require_purchase
      //   - Week 1          - inherit
      //     ->[Workout 1]   - referenced via CollectionItem
      //
      // - Workout 1         - inherit
      //
      // In this case, cause Workout 1 is set to inherit, it needs to inherit the access rules
      // of the page it appears on. Therefore, after checking the Workout 1 tree, we "switch" to
      // the Week 1 tree to check the access rules there, which results in resolving the "Program"
      // pages access rules
      return findAccessRoot(
        currentPageId,
        currentPageId,
        true,
        foundInOriginalTree
      )
    }
  }

  return findAccessRoot(
    access.parent,
    currentPageId,
    hasSwitchedTree,
    foundInOriginalTree
  )
}

export const computeAccess = (
  accessRootId: string | number | null,
  access: AccessTreeAccessRoot,
  opts: {
    currentUser: User | Record<string, never> | null
    fromChild?: boolean
    pageSubscriptions?: { [key: string]: boolean }
    pageProgresses?: PageProgresses
  } = {
    currentUser: null,
    fromChild: false,
    pageSubscriptions: {},
    pageProgresses: {}
  }
):
  | GrantedAccessResponse
  | RequirePurchaseAccessResponse
  | RequireAuthenticationAccessResponse
  | IsPrivateAccessResponse
  | IsDrippedAccessResponse => {
  const { fromChild, currentUser, pageSubscriptions, pageProgresses } = opts

  // Is Private
  if (access.is_private) {
    if (!isPresent(currentUser)) {
      return { granted: false, reason: REASONS.IS_PRIVATE }
    }

    if (
      pageSubscriptions &&
      accessRootId &&
      !!pageSubscriptions[accessRootId]
    ) {
      if (access.require_purchase && !currentUser.isAdmin) {
        const productIds = access.products?.map((p: Product) => p.id) || []

        if (!currentUser.activeProductIds) {
          return {
            granted: false,
            reason: REASONS.REQUIRE_PURCHASE,
            data: {
              product_ids: productIds,
              purchase_overlay: access.purchase_overlay?.id
            }
          }
        }

        if (
          currentUser.activeProductIds.some((id: number) =>
            productIds.includes(id)
          )
        ) {
          return { granted: true }
        }

        return {
          granted: false,
          reason: REASONS.REQUIRE_PURCHASE,
          data: {
            product_ids: access.products?.map((p: Product) => p.id) || [],
            purchase_overlay: access.purchase_overlay?.id
          }
        }
      } else {
        return { granted: true }
      }
    }

    return {
      granted: false,
      reason: REASONS.IS_PRIVATE
    }
  }

  // Require Purchase
  if (access.require_purchase) {
    const productIds = access.products?.map((p: Product) => p.id) || []

    if (access.allow_browsing && !fromChild) {
      return { granted: true }
    }

    if (!isPresent(currentUser)) {
      return {
        granted: false,
        reason: REASONS.REQUIRE_PURCHASE,
        data: {
          product_ids: productIds,
          purchase_overlay: access.purchase_overlay?.id
        }
      }
    }

    if (!currentUser.activeProductIds) {
      return {
        granted: false,
        reason: REASONS.REQUIRE_PURCHASE,
        data: {
          product_ids: productIds,
          purchase_overlay: access.purchase_overlay?.id
        }
      }
    }

    if (
      currentUser.isAdmin ||
      currentUser.activeProductIds.some((id: number) => productIds.includes(id))
    ) {
      return { granted: true }
    }

    return {
      granted: false,
      reason: REASONS.REQUIRE_PURCHASE,
      data: {
        product_ids: access.products?.map((p: Product) => p.id) || [],
        purchase_overlay: access.purchase_overlay?.id
      }
    }
  }

  // Drip
  if (access.is_scheduled) {
    if (!isPresent(currentUser)) {
      return { granted: false, reason: REASONS.IS_SCHEDULED }
    }

    if (!isPresent(accessRootId)) {
      return { granted: false, reason: REASONS.IS_SCHEDULED }
    }

    // In the case of drip, the page itself is an access root because it has to
    // have `inherit` false to have `is_scheduled` true
    const progressRoot = findProgressRoot(accessRootId)

    if (!progressRoot) {
      return { granted: false, reason: REASONS.IS_SCHEDULED }
    }

    const progress: PageProgress | Record<string, never> | null = isPresent(
      pageProgresses
    )
      ? pageProgresses[progressRoot]
      : null

    if (
      isPresent(progress) &&
      "started_at" in progress &&
      isPresent(progress.started_at)
    ) {
      const startedAt = (progress as PageProgress).started_at

      const availableAfter = add(
        access.schedule_period,
        access.schedule_interval,
        startedAt as any
      )

      if (isFuture(availableAfter)) {
        return {
          granted: false,
          reason: REASONS.IS_SCHEDULED,
          data: { available_after: availableAfter }
        }
      } else {
        return { granted: true }
      }
    } else {
      return { granted: false, reason: REASONS.IS_SCHEDULED }
    }
  }

  // Require Authentication
  if (access.require_authentication) {
    if (isPresent(currentUser)) {
      return { granted: true }
    }

    if (access.allow_browsing && !fromChild) {
      return { granted: true }
    } else {
      return {
        granted: false,
        reason: REASONS.REQUIRE_AUTHENTICATION
      }
    }
  }

  // Public
  return { granted: true }
}

export const getPageAccess = ({
  pageId,
  pageAccess = null,
  currentPageId = null,
  currentUser = store.getters["auth/currentUser"],
  appAccess = store.getters["appAccess"],
  pageSubscriptions = store.state.pages.pageSubscriptions,
  pageProgresses = store.state.pages.pageProgress,
  fromAttachment = false
}: {
  pageId: PageId
  pageAccess?: PageAccess | null
  currentUser?: User | Record<string, never> | null
  appAccess: GlobalAppAccess
  currentPageId?: PageId | null
  pageSubscriptions?: { [key: PageId]: boolean }
  pageProgresses?: PageProgresses
  fromAttachment?: boolean
}):
  | GrantedAccessResponse
  | RequirePurchaseAccessResponse
  | RequireAuthenticationAccessResponse
  | IsPrivateAccessResponse
  | IsDrippedAccessResponse => {
  let access: AccessTreeAccess =
    pageAccess || store.getters["pages/getPageAccess"](pageId)

  if (!access) {
    // Legacy content types
    access = { inherit: true, parent: null }
  }

  if (access.inherit) {
    const [rootAccess, accessRootId, didSwitch] = findAccessRoot(
      pageId,
      currentPageId || null
    )

    if (rootAccess) {
      return computeAccess(accessRootId, rootAccess, {
        fromChild: true,
        currentUser,
        pageSubscriptions,
        pageProgresses
      })
    } else {
      // We should prevent browsing if we switched trees (i.e. a page on the current page is set to inherit)
      // OR if the page is not a root
      const shouldPreventBrowsing =
        didSwitch || !!access.parent || fromAttachment

      // Global Is Private
      if (appAccess.is_private) {
        if (isPresent(currentUser)) {
          if (!currentUser.subscriberId) {
            return { granted: false, reason: REASONS.IS_PRIVATE }
          }

          if (appAccess.is_subscriber_only && !currentUser.isAdmin) {
            const productIds = appAccess.app_products.map((p: Product) => p.id)

            if (
              currentUser.activeProductIds &&
              currentUser.activeProductIds.some((id: number) =>
                productIds.includes(id)
              )
            ) {
              return { granted: true }
            }

            return {
              granted: false,
              reason: REASONS.REQUIRE_PURCHASE,
              data: {
                product_ids: productIds,
                purchase_overlay: appAccess.purchase_overlay?.id
              }
            }
          } else {
            return { granted: true }
          }
        }

        return {
          granted: false,
          reason: REASONS.IS_PRIVATE
        }
      }

      // Global Require Purchase
      if (appAccess.is_subscriber_only) {
        const productIds =
          appAccess.app_products?.map((p: Product) => p.id) || []

        if (appAccess.allow_anonymous_browsing && !shouldPreventBrowsing) {
          return { granted: true }
        }

        if (!isPresent(currentUser)) {
          return {
            granted: false,
            reason: REASONS.REQUIRE_PURCHASE,
            data: {
              product_ids: productIds,
              purchase_overlay: appAccess.purchase_overlay?.id
            }
          }
        }

        if (currentUser.isAdmin) return { granted: true }

        if (!currentUser.activeProductIds) {
          return {
            granted: false,
            reason: REASONS.REQUIRE_PURCHASE,
            data: {
              product_ids: productIds,
              purchase_overlay: appAccess.purchase_overlay?.id
            }
          }
        }

        if (
          currentUser.activeProductIds.some((id: number) =>
            productIds.includes(id)
          )
        ) {
          return { granted: true }
        }

        return {
          granted: false,
          reason: REASONS.REQUIRE_PURCHASE,
          data: {
            product_ids: productIds,
            purchase_overlay: appAccess.purchase_overlay?.id
          }
        }
      }

      // Global Require Authentication
      if (appAccess.require_sign_in) {
        if (appAccess.allow_anonymous_browsing && !shouldPreventBrowsing) {
          return { granted: true }
        }

        if (isPresent(currentUser)) {
          return { granted: true }
        }

        return {
          granted: false,
          reason: REASONS.REQUIRE_AUTHENTICATION
        }
      }

      return { granted: true }
    }
  } else {
    return computeAccess(pageId, access, {
      currentUser,
      pageSubscriptions,
      pageProgresses,
      fromChild: fromAttachment
    })
  }
}
