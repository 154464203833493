import Vue from "vue"

export default {
  namespaced: true,

  state: {
    isFullscreen: false,
    lastMutedStatus: true // Default to muted to ensure playback
  },

  mutations: {
    setLastMutedStatus(state, isMuted = true) {
      Vue.set(state, "lastMutedStatus", isMuted)
    },

    setFullscreen(state, isFullscreen = true) {
      Vue.set(state, "isFullscreen", isFullscreen)
    }
  }
}
