import { isPresent, isBoolean } from "@/lib/utils"

export const showBottomPadding = props =>
  isPresent(props) && isBoolean(props.show_bottom_padding)
    ? props.show_bottom_padding
    : true // Add padding as default

export const getScrollRootElement = () => document

export const ROOT_TREE_ID = "root"

export const traverseTree = (tree, cb) => {
  if (!tree) {
    return tree
  }

  tree.forEach(page => {
    cb(page)

    if (page.pages) {
      traverseTree(page.pages, cb)
    }
  })
}
