import Vue from "vue"
import ApiClient from "@/lib/api-client"

import { toDate } from "@/lib/date-utils"

export const toItemId = (id, type) => `${type.toLowerCase()}|${id}`

export default {
  namespaced: true,

  state: {
    scheduledItems: {},
    scheduledItemsByItem: {}
  },

  mutations: {
    setScheduledItem(state, item) {
      Vue.set(state.scheduledItems, item.id, item)

      const itemScheduledItemKey = toItemId(item.item_id, item.item_type)

      if (!state.scheduledItemsByItem[itemScheduledItemKey]) {
        Vue.set(state.scheduledItemsByItem, itemScheduledItemKey, {})
      }

      Vue.set(state.scheduledItemsByItem[itemScheduledItemKey], item.id, true)
    },

    deleteScheduledItem(state, itemId) {
      const item = state.scheduledItems[itemId]

      const itemScheduledItemKey = toItemId(item.item_id, item.item_type)

      if (state.scheduledItemsByItem[itemScheduledItemKey]) {
        Vue.delete(state.scheduledItemsByItem[itemScheduledItemKey], item.id)
      }

      Vue.delete(state.scheduledItems, itemId)
    }
  },

  getters: {
    getScheduledItem(state) {
      return id => state.scheduledItems[id]
    },

    getScheduledItemsFor(state, getters) {
      return (id, type) =>
        Object.keys(state.scheduledItemsByItem[toItemId(id, type)] || {}).map(
          getters.getScheduledItem
        )
    }
  },

  actions: {
    fetchScheduledItems({ commit }, { from, to, itemId, itemType }) {
      return ApiClient.get(`/schedule`, {
        params: {
          item_id: itemId,
          item_type: itemType,
          from: from ? toDate(from, "YYYY-MM-DD") : null,
          to: to ? toDate(to, "YYYY-MM-DD") : null
        }
      }).then(({ data }) => {
        if (data.items) {
          data.items.forEach(item => commit("setScheduledItem", item))
        }

        return data
      })
    },

    createScheduledItem({ commit }, { itemId, itemType = "Page", date }) {
      return ApiClient.post("/schedule", {
        item_id: itemId,
        item_type: itemType,
        date: toDate(date, "YYYY-MM-DD")
      }).then(res => {
        commit("setScheduledItem", res.data)

        return res.data
      })
    },

    rescheduleItem(
      { commit },
      { scheduledItemId, date, shiftSubsequent = false }
    ) {
      return ApiClient.put(`/schedule/${scheduledItemId}`, {
        date: toDate(date, "YYYY-MM-DD"),
        shift_subsequent: shiftSubsequent
      }).then(({ data }) => {
        commit("setScheduledItem", data.rescheduled_item)

        data.rescheduled_siblings.forEach(item => {
          commit("setScheduledItem", item)
        })

        return data
      })
    },

    duplicateScheduledItem({ commit }, { scheduledItemId, date }) {
      return ApiClient.post(`/schedule/${scheduledItemId}/duplicate`, {
        date: toDate(date, "YYYY-MM-DD")
      }).then(res => {
        commit("setScheduledItem", res.data)

        return res.data
      })
    },

    removeScheduledItem({ commit }, scheduledItemId) {
      return ApiClient.delete(`/schedule/${scheduledItemId}`).then(() => {
        commit("deleteScheduledItem", scheduledItemId)
      })
    },

    removeSubscriberSchedulesByItem({ commit, getters }, { itemId, itemType }) {
      const scheduledItems = getters.getScheduledItemsFor(itemId, itemType)

      scheduledItems.forEach(item => {
        commit("deleteScheduledItem", item.id)
      })
    }
  }
}
