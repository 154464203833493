<template>
  <component
    :is="template"
    :class="[
      { '--no-padding-bottom': noPaddingBottom || isCypress() },
      'app-page'
    ]"
    v-bind="props"
    v-on="$listeners"
  />
</template>

<script>
import Loader from "@/components/_global/vLoader"

import { isCypress } from "@/lib/is-platform"

import GenericTemplate from "@/components/page-templates/GenericTemplate"

const withLoader = component => ({
  component,
  loading: Loader
})

export default {
  components: {
    GenericTemplate,
    ProgramTemplate: () =>
      withLoader(import("@/components/page-templates/ProgramTemplate")),
    WorkoutTemplate: () =>
      withLoader(import("@/components/page-templates/WorkoutTemplate")),
    MealTemplate: () =>
      withLoader(import("@/components/page-templates/MealTemplate")),
    ArticleTemplate: () =>
      withLoader(import("@/components/page-templates/ArticleTemplate")),
    ExerciseTemplate: () =>
      withLoader(import("@/components/page-templates/ExerciseTemplate")),
    StreamTemplate: () =>
      withLoader(import("@/components/page-templates/StreamTemplate")),
    CollectionTemplate: () =>
      withLoader(import("@/components/page-templates/CollectionTemplate")),
    EventOccurrenceTemplate: () =>
      withLoader(import("@/components/page-templates/EventOccurrenceTemplate")),
    PackTemplate: () =>
      withLoader(import("@/components/page-templates/PackTemplate")),
    FormTemplate: () =>
      withLoader(import("@/components/page-templates/FormTemplate"))
  },

  props: {
    template: { type: String, required: true },
    props: { type: Object, default: () => ({}) },
    noPaddingBottom: { type: Boolean, default: false }
  },

  methods: {
    isCypress
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";

.app-page {
  min-height: 1px; // So cypress recognises an empty page
  padding-bottom: $size--13;

  &.--no-padding-bottom {
    padding-bottom: 0;
  }
}
</style>
