<template>
  <div
    :class="[
      'search-wrapper d-flex align-items-center',
      { '--inline': inline }
    ]"
    @click="() => $emit('click')"
  >
    <div class="input-prepend h-100 d-flex align-items-center px-1">
      <v-icon icon="search" size="1.5rem" class="search-icon" />
    </div>
    <v-input
      v-bind="$attrs"
      ref="input"
      flush
      :value="value"
      :placeholder="placeholder"
      class="v-search-input flex-grow-1 h-100 pl-0"
      @input="value => $emit('input', value)"
    />
    <v-button
      v-if="value"
      x-small
      ghost
      class="p-2 search-clear"
      @click="() => $emit('clear')"
    >
      <v-icon icon="close" size="1rem" />
    </v-button>
  </div>
</template>

<script>
import vInput from "@/components/forms/vInput"

export default {
  components: {
    vInput
  },

  model: {
    prop: "value",
    event: "input"
  },

  props: {
    value: { type: String, default: null },
    placeholder: {
      type: String,
      default: function() {
        return this.$t("components.forms.vSearch.searchPlaceholder")
      }
    },
    autofocus: { type: Boolean, default: false },
    inline: { type: Boolean, default: false }
  },

  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.input && this.$refs.input.focus()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins";

.search-wrapper {
  height: $input--base-height;
  overflow: hidden;

  &:not(.--inline) {
    @include block-link;
  }

  .search-icon {
    color: var(--primary-brand-color-hex);
  }
}

.search-clear {
  transition: opacity 0.16s;

  &:hover {
    background: inherit;
    opacity: 0.7;
  }
}
</style>

<style lang="scss">
.search-wrapper {
  &.--inline {
    input {
      background-color: inherit;
    }
  }
}

.v-search-input {
  .v-input__wrapper {
    height: 100%;

    .v-input {
      height: 100%;
      font-size: 1rem;
    }
  }
}
</style>
