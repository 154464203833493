export const BLOCK_BASE_HEIGHT = 200;
export const IMAGE_BASE_WIDTH = 375;
export const BORDER_RADIUS_BASE = 12;
export const DEFAULT_RADIUS_RATIO = 0;
export const PX_PER_REM = 16; // Must match the root font-size
export const HERO_HEIGHT_RATIO_RANGE = {
  MIN: 10,
  MAX: 300,
};
export const HEIGHT_UNITS = {
  PIXEL: "pixel",
  PERCENT: "percent",
};

export const ratioToMultiplier = (ratio, min, max, opts = {}) => {
  if (ratio < min || ratio > max) {
    throw "Ratio Out Of Range";
  }

  const percent = Math.round(((ratio - min) / (max - min)) * 100);

  return opts.percent ? percent : percent / 100;
};

export const withRatio = (baseValue, ratio, opts = {}) => {
  const options = { unit: "px", integer: false, ...(opts || {}) };
  const value =
    Math.round((0.01 * baseValue * ratio + Number.EPSILON) * 100) / 100;

  return `${options.integer ? Math.round(value) : value}${
    options.unit ? options.unit : ""
  }`;
};

export const toPadding = (top, x, bottom, opts = {}) => {
  const options = { unit: "rem", baseValue: 1, ...(opts || {}) };

  const paddingTop = withRatio(options.baseValue, top, { unit: options.unit });
  const paddingX = withRatio(options.baseValue, x, { unit: options.unit });
  const paddingBottom = withRatio(options.baseValue, bottom, {
    unit: options.unit,
  });

  return `${paddingTop} ${paddingX} ${paddingBottom}`;
};
