var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVisible)?_c('portal',{attrs:{"selector":"#cta-row-portal"}},[_c('transition',{attrs:{"appear":"","name":"fade-in-up"}},[(_vm.show)?_c('div',_vm._b({ref:"ctaRow",class:[
        {
          '--no-spacing': _vm.noSpacing,
          '--overlay': _vm.overlay,
          '--overlay-modal': _vm.overlayModal,
          'z-3': !_vm.overlayModal && !_vm.overlay
        },
        _vm.classes,
        'v-cta-row'
      ],style:({ transform: ("translateY(-" + _vm.offsetBottom + "px)") })},'div',_vm.$attrs,false),[(_vm.$slots.float)?[_c('div',{class:[
            { '--safe-padding': !_vm.$slots.default },
            'v-cta-row__float-wrapper d-flex align-items-center justify-content-center'
          ]},[_c('div',{class:[{ 'w-100': _vm.fullWidthFloat }, 'v-cta-row__float-content']},[_vm._t("float")],2)])]:_vm._e(),_c('div',{class:[
          {
            '--white-bg': _vm.whiteBg,
            '--overlay': _vm.overlay,
            '--empty': !_vm.$slots.default
          },
          'v-cta-row__content-wrapper'
        ]},[_c('div',{class:[
            {
              '--empty': !_vm.$slots.default,
              '--no-spacing': _vm.noSpacing,
              '--row': _vm.row,
              '--align-center': _vm.alignCenter,
              '--align-start': _vm.alignStart
            },
            'v-cta-row__content container'
          ]},[_vm._t("default")],2)])],2):_vm._e()])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }