import { isPresent } from "@/lib/utils"

import ApiClient from "@/lib/api-client"

export default {
  namespaced: true,

  state: {},

  actions: {
    fetchItems(
      _,
      {
        contentType,
        duration,
        offset,
        limit,
        dynamicDirection,
        includeCurrent,
        withTags,
        filterTags,
        orderBy,
        withAllTags,
        withSubscriberProperty,
        progress,
        scheduleType,
        timezone,
        pageId,
        page = 1
      }
    ) {
      let params = {
        offset,
        limit,
        timezone,
        page,
        page_id: pageId,
        order_by: orderBy
      }

      if (isPresent(dynamicDirection)) {
        params = {
          ...params,
          direction: dynamicDirection
        }
      }

      if (isPresent(dynamicDirection) && isPresent(duration)) {
        params = {
          ...params,
          duration: dynamicDirection == "today" ? null : duration,
          include_current: includeCurrent
        }
      }

      if (isPresent(withTags)) {
        params = {
          ...params,
          with_tags: withTags,
          with_all_tags: withAllTags
        }
      }

      if (isPresent(filterTags)) {
        params = {
          ...params,
          filter_tags: filterTags
        }
      }

      if (isPresent(withSubscriberProperty)) {
        params["with_property"] = withSubscriberProperty
      }

      if (isPresent(progress)) {
        params["progress"] = progress
      }

      if (isPresent(scheduleType)) {
        params["schedule_type"] = scheduleType
      }

      return ApiClient.get(`/content/${contentType}`, {
        params
      }).then(res => {
        return {
          items: res.data.items,
          total_count: res.data.total_count
        }
      })
    }
  },

  mutations: {},

  getters: {}
}
