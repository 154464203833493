import "@/lib/audio/types"

import Emitter from "tiny-emitter"

import { PLAYER_EVENTS } from "@/lib/audio/shared"

import {
  NATIVE_APP_EVENT,
  MESSAGE_TYPES,
  postMessageToNativeApp,
  buildMessage
} from "@/lib/app-store-utils"

// MUST MATCH: audio-player.ts in app-store-apps
const NATIVE_PLAYER_EVENTS = {
  SET_SOURCE: "set-source",
  PLAY: "play",
  PAUSE: "pause",
  SEEK: "seek",
  REWIND: "rewind",
  FORWARD: "forward",
  READY: "ready",
  SET_LOOP: "set-loop",
  SET_MUTED: "set-muted",
  SET_PLAYBACK_SPEED: "set-playback-speed",
  STATE_UPDATE: "state-update",
  PROGRESS_UPDATE: "progress-update",
  DESTROY: "destroy"
}

// MUST MATCH: audio-player.ts in app-store-apps
const PLAYER_STATUS = {
  PLAYING: "playing",
  PAUSED: "paused",
  ENDED: "ended",
  STOPPED: "stopped",
  BUFFERING: "buffering",
  LOADING: "loading",
  READY: "ready"
}

export default class NativePlayer {
  constructor(audioPlayer) {
    this.audioPlayer = audioPlayer

    this.emitter = new Emitter()

    // Public Getter / Setter
    this.currentTime = 0
    this.buffered = 0
    this.muted = false

    // State
    this.isRestoringSource = false

    // Handle Events from Native Player
    this._onNativePlayerUpdate(({ event, payload }) => {
      switch (event) {
        case NATIVE_PLAYER_EVENTS.STATE_UPDATE:
          this._handleStateUpdate(payload.state)

          break

        case NATIVE_PLAYER_EVENTS.PROGRESS_UPDATE:
          this.muted = payload.muted
          this.currentTime = payload.currentTime
          this.buffered = payload.buffered

          if (!this.audioPlayer.currentSource) {
            // This occurs most often if the webview is reloaded
            // in the native app. The player is still loaded and therefore
            // sending events, but the webview has lost its state.
            // We need to restore the source and the current time.
            this.isRestoringSource = true

            this.audioPlayer.setSource(payload.source)
            this.emitter.emit(PLAYER_EVENTS.PLAY)

            this.isRestoringSource = false
          }

          this.emitter.emit(PLAYER_EVENTS.TIME_UPDATE)

          break

        case NATIVE_PLAYER_EVENTS.READY:
          this.emitter.emit(PLAYER_EVENTS.READY)

          break

        default:
          console.log(`[native] Unhandled event: ${event}`)
      }
    })
  }

  play() {
    this._sendMessage(PLAYER_EVENTS.PLAY)
  }

  pause() {
    this._sendMessage(PLAYER_EVENTS.PAUSE)
  }

  seek(seconds) {
    this._sendMessage(PLAYER_EVENTS.SEEK, { time: seconds })
  }

  rewind(seconds) {
    this.seek(Math.max(this.currentTime - seconds, 0))
  }

  forward(seconds, duration) {
    this.seek(Math.min(this.currentTime + seconds, duration))
  }

  /**
   * Sets the audio source for the player.
   * @param {AudioSource} source - The source object containing audio and page information.
   */
  setSource(source) {
    if (this.isRestoringSource) return

    this.currentTime = 0
    this.buffered = 0

    this._sendMessage(NATIVE_PLAYER_EVENTS.SET_SOURCE, source)
  }

  setLoop(loop) {
    this._sendMessage(NATIVE_PLAYER_EVENTS.SET_LOOP, { loop })
  }

  setPlaybackSpeed(speed) {
    this._sendMessage(NATIVE_PLAYER_EVENTS.SET_PLAYBACK_SPEED, { speed })
  }

  setMuted(muted) {
    this._sendMessage(NATIVE_PLAYER_EVENTS.SET_MUTED, { muted })
    this.muted = muted
  }

  on(event, callback) {
    this.emitter.on(event, callback)
  }

  destroy() {
    this.emitter.off()
    this._sendMessage(NATIVE_PLAYER_EVENTS.DESTROY)
  }

  _sendMessage(event, payload = {}) {
    postMessageToNativeApp(
      buildMessage(MESSAGE_TYPES.UPDATE_NATIVE_PLAYER, { event, payload })
    )
  }

  _handleStateUpdate(state) {
    switch (state) {
      case PLAYER_STATUS.PLAYING:
        this.emitter.emit(PLAYER_EVENTS.PLAY)
        break

      case PLAYER_STATUS.PAUSED:
        this.emitter.emit(PLAYER_EVENTS.PAUSE)
        break

      case PLAYER_STATUS.BUFFERING:
      case PLAYER_STATUS.LOADING:
        this.emitter.emit(PLAYER_EVENTS.WAITING)
        break

      case PLAYER_STATUS.ENDED:
        this.emitter.emit(PLAYER_EVENTS.ENDED)
        break

      case PLAYER_STATUS.READY:
        this.emitter.emit(PLAYER_EVENTS.READY)

        break

      default:
        console.log(`[native] Unhandled state: ${state}`)
    }
  }

  _onNativePlayerUpdate(callback) {
    window.addEventListener(NATIVE_APP_EVENT, event => {
      switch (event.detail.type) {
        case MESSAGE_TYPES.NATIVE_PLAYER_HAS_UPDATED: {
          callback(event.detail.data)
        }
      }
    })
  }
}
