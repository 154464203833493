import { isPresent } from "@/lib/utils"

export const FIELD_TYPES = {
  ACCEPTANCE: "acceptance",
  SHORT_ANSWER: "short_answer",
  LONG_ANSWER: "long_answer",
  MULTI_SELECT: "multi_select",
  IMAGE_UPLOAD: "image_upload",
  NUMBER: "number",
  DATE: "date"
}

export const FIELD_ICONS = {
  [FIELD_TYPES.ACCEPTANCE]: "check",
  [FIELD_TYPES.SHORT_ANSWER]: "short_text",
  [FIELD_TYPES.LONG_ANSWER]: "notes",
  [FIELD_TYPES.MULTI_SELECT]: "playlist_add_check",
  [FIELD_TYPES.IMAGE_UPLOAD]: "filter",
  [FIELD_TYPES.NUMBER]: "tag",
  [FIELD_TYPES.DATE]: "event"
}

export const CAPTURE_STATUS = {
  SAVED: "saved",
  MISSED: "missed"
}

export const forEachGroupField = (formGroup, callback) => {
  if (formGroup.form_groups) {
    formGroup.form_groups.forEach(group => forEachGroupField(group, callback))
  }

  if (formGroup.form_fields) {
    formGroup.form_fields.forEach(field => callback(field))
  }
}

export const forEachFormField = (form, callback) => {
  form.form_groups.forEach(group => {
    forEachGroupField(group, callback)
  })
}

export const getAssessmentFields = formGroups => {
  const fields = []

  formGroups.forEach(group => {
    forEachGroupField(group, field => {
      if (isPresent(field.correct_answer)) {
        fields.push(field)
      }
    })
  })

  return fields
}

export const MISSED_VALUE = "0"
