import type { TextBlock, PageAttributes, PageAttribute, Block } from "./types";

import { BLOCK_TYPES } from "./blocks";

export const TYPES = {
  IMAGE: "image",
  VIDEO: "video",
  AUDIO: "audio",
  STRING: "string",
  RICH_TEXT: "rich_text",
} as const;

export const BLOCK_PAGE_ATTRIBUTES = {
  IMAGE_PAGE_ATTRIBUTE: "image_page_attribute_slug",
  VIDEO_PAGE_ATTRIBUTE: "video_page_attribute_slug",
  AUDIO_PAGE_ATTRIBUTE: "audio_page_attribute_slug",
  PAGE_ATTRIBUTE: "page_attribute_slug",
} as const;

export const BUILT_IN_PAGE_ATTRIBUTES = {
  TITLE: "page.title",
};

export const getTextContent = (
  textBlock: TextBlock,
  pageAttributes: PageAttributes = {}
): string => {
  const { content, page_attribute_slug } = textBlock.data;

  if (!page_attribute_slug) return content;

  const attribute = pageAttributes[page_attribute_slug];

  if (!attribute) return content;
  if (!attribute?.value) return content;

  if (attribute.type === TYPES.STRING) {
    const body = new DOMParser()
      .parseFromString(content, "text/html")
      .documentElement.querySelector("body");

    if (!body) return content;

    const node = body.childNodes[0] as Element;

    if (!node) return content;

    const replaceWithHtml = !!node.textContent
      ? node.outerHTML.replace(node.textContent, attribute.value)
      : (node as any).outerHTML.replace("></", `>${attribute.value}</`);

    return node ? body.innerHTML?.replace(node.outerHTML, replaceWithHtml) : "";
  } else if (attribute.type === TYPES.RICH_TEXT) {
    return attribute.value.startsWith("<")
      ? attribute.value
      : `<p>${attribute.value}</p>`;
  }

  return content;
};

export const parseToAttributeValue = (
  text: string | null,
  pageAttribute: PageAttribute
): string => {
  if (!text) return "";

  if (pageAttribute.type === TYPES.STRING) {
    return (
      new DOMParser()
        .parseFromString(text, "text/html")
        .documentElement?.querySelector("body")?.childNodes[0]?.textContent ||
      ""
    );
  } else if (pageAttribute.type === TYPES.RICH_TEXT) {
    return text.startsWith("<") ? text : `<p>${text}</p>`;
  } else {
    return "";
  }
};

export const getDefaultAttributeType = (block: Block) => {
  switch (block.type) {
    case BLOCK_TYPES.TEXT_BLOCK:
      const childNodes =
        new DOMParser()
          .parseFromString(block.data.content, "text/html")
          .documentElement?.querySelector("body")?.childNodes?.length || 0;

      return childNodes > 1 ? TYPES.RICH_TEXT : TYPES.STRING;
    case BLOCK_TYPES.HERO_BLOCK:
      return TYPES.IMAGE;
    default:
      return TYPES.STRING;
  }
};

export const attributeIcon = (attributeType: string): string => {
  switch (attributeType) {
    case TYPES.STRING:
      return "format_size";

    case TYPES.RICH_TEXT:
      return "notes";

    case TYPES.IMAGE:
      return "image";

    case TYPES.VIDEO:
      return "slideshow";

    case TYPES.AUDIO:
      return "headphones";

    default:
      return "";
  }
};
