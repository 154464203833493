<template>
  <div class="container" :style="{ padding: padding }">
    <block-action
      :key="key"
      :block-action="blockAction"
      @purchaseChecked="handlePurchaseChecked"
    >
      <template v-slot="{ isLocked, isLoadingAccess, availableAfter }">
        <v-button
          :block="display === DISPLAY_OPTIONS.BLOCK"
          :secondary="variant === VARIANT_OPTIONS.SECONDARY"
          :secondary-color="secondaryColor"
          :light="variant === VARIANT_OPTIONS.WHITE"
          :primary="variant === VARIANT_OPTIONS.PRIMARY"
          :small="size === SIZE_OPTIONS.SMALL"
          :large="size === SIZE_OPTIONS.LARGE"
          :flat="btnStyle === STYLE_OPTIONS.FLAT"
          :is-loading="isLoading"
        >
          <span data-role="button-content" v-html="displayText" />

          <template v-if="isLocked || isLoadingAccess">
            <access-lock
              :is-loading="isLoadingAccess"
              :available-after="availableAfter"
            />
          </template>
        </v-button>
      </template>
    </block-action>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

import EventBus, { EVENTS } from "@/lib/event-bus"
import enumValidator from "@/lib/validators/enum-validator"
import { isBlockActionLink } from "@/lib/stream-utils"
import { toPadding } from "@shared/block-ratio"

import BlockAction from "@/components/BlockAction"

const DISPLAY_OPTIONS = {
  BLOCK: "block",
  INLINE: "inline"
}

const VARIANT_OPTIONS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  WHITE: "white"
}

const STYLE_OPTIONS = {
  RAISED: "raised",
  FLAT: "flat"
}

const SIZE_OPTIONS = {
  SMALL: "small",
  REGULAR: "regular",
  LARGE: "large"
}

export default {
  components: {
    BlockAction,
    AccessLock: () => import("@/components/AccessLock")
  },

  props: {
    text: { type: String, default: "Button Text" },
    postPurchaseText: { type: String, default: "Continue" },
    link: { type: String, default: "" },
    spaceXRatio: {
      type: Number,
      default: 100
    },
    spaceTopRatio: {
      type: Number,
      default: 100
    },
    spaceBottomRatio: {
      type: Number,
      default: 100
    },
    display: {
      type: String,
      default: "block",
      validator: enumValidator(Object.values(DISPLAY_OPTIONS))
    },
    variant: {
      type: String,
      default: "secondary",
      validator: enumValidator(Object.values(VARIANT_OPTIONS))
    },
    btnStyle: {
      type: String,
      default: "flat",
      validator: enumValidator(Object.values(STYLE_OPTIONS))
    },
    size: {
      type: String,
      default: "regular",
      validator: enumValidator(Object.values(SIZE_OPTIONS))
    },
    blockAction: {
      type: Object,
      default: () => ({})
    }
  },

  mounted() {
    EventBus.$on(EVENTS.CHECKOUT_COMPLETED, this.handleCheckoutCompleted)
  },

  beforeDestroy() {
    EventBus.$off(EVENTS.CHECKOUT_COMPLETED, this.handleCheckoutCompleted)
  },

  computed: {
    ...mapState(["global"]),
    ...mapGetters("auth", ["hasCheckedAuthentication", "isAuthenticated"]),

    linkValue() {
      return this.blockAction && isBlockActionLink(this.blockAction.action)
        ? this.blockAction.link
        : null
    },

    padding() {
      return toPadding(
        this.spaceTopRatio,
        this.spaceXRatio,
        this.spaceBottomRatio
      )
    },

    secondaryColor() {
      return this.global?.theme?.secondary_color?.hex
    }
  },

  data() {
    return {
      displayText: this.text,
      key: 0,
      isLoading: false,
      DISPLAY_OPTIONS,
      VARIANT_OPTIONS,
      STYLE_OPTIONS,
      SIZE_OPTIONS
    }
  },

  watch: {
    hasCheckedAuthentication: {
      immediate: true,
      handler: function() {
        this.isLoading = !this.hasCheckedAuthentication
        this.key++
      }
    },
    isAuthenticated() {
      this.key++
    }
  },

  methods: {
    handleCheckoutCompleted() {
      this.key++
    },

    handlePurchaseChecked(hasPurchased) {
      this.displayText = hasPurchased ? this.postPurchaseText : this.text
    }
  }
}
</script>

<style lang="scss">
.v-button {
  .access-lock {
    bottom: 20% !important;
  }
}
</style>
