// Used when sessionStorage is not available
const MEMORY_SESSION_STORE = {
  store: {},
  setItem: function(key, value) {
    this.store[key] = value
    return true
  },
  getItem: function(key) {
    return this.store[key]
  },
  removeItem: function(key) {
    delete this.store[key]
    return true
  }
}

export const setItem = (key, value) => {
  try {
    return window.sessionStorage.setItem(key, value)
  } catch (e) {
    return MEMORY_SESSION_STORE.setItem(key, value)
  }
}

export const getItem = key => {
  try {
    return window.sessionStorage.getItem(key)
  } catch (e) {
    return MEMORY_SESSION_STORE.getItem(key)
  }
}
