<template>
  <div
    :class="[{ '--no-padding': noPadding }, 'v-html']"
    v-if="html"
    v-html="html"
  ></div>
</template>

<script>
export default {
  props: {
    html: { type: String, default: () => null },
    noPadding: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";

.v-html {
  &.--no-padding {
    padding: 0;
  }

  p {
    margin-top: 0;
  }

  img {
    max-width: 100%;
  }

  ol,
  ul {
    padding-left: $size--5;

    li {
      padding-left: $size--1;
      font-size: inherit;
    }
  }
}
</style>
