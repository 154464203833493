import scssVariables from "@/assets/styles/variables.module.scss"
import EventBus, { EVENTS } from "@/lib/event-bus"
import { isPresent } from "@/lib/utils"

import { BLOCK_TYPES } from "@shared/blocks"
import { TYPES, BLOCK_PAGE_ATTRIBUTES } from "@shared/page-attributes"

export const BLOCK_DEFAULT_HEIGHT_RATIO = 100

export const ROLES = {
  PAGE: "page",
  BLOCK: "block",
  BLOCK_CONTAINER: "block-container"
}

export const APP_COLORS = {
  WHITE: "white",
  LIGHT_GREY: "light_grey",
  GREY: "grey",
  BLACK: "black"
}

export const BRAND_COLORS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  DARK: "dark",
  LIGHT: "light"
}

export const ALL_COLORS = {
  ...APP_COLORS,
  ...BRAND_COLORS
}

export const COLOR_MODES = {
  LIGHT: "light",
  DARK: "dark"
}

export const BACKGROUND_COLORS = {
  [APP_COLORS.WHITE]: scssVariables["color-fixed--white"],
  [APP_COLORS.LIGHT_GREY]: scssVariables["color-fixed--grey-20"],
  [APP_COLORS.GREY]: scssVariables["color-fixed--grey-60"],
  [APP_COLORS.BLACK]: scssVariables["color-fixed--black"],
  [BRAND_COLORS.PRIMARY]: "var(--primary-brand-color-hex)",
  [BRAND_COLORS.SECONDARY]: "var(--secondary-brand-color-hex)",
  [BRAND_COLORS.DARK]: "var(--dark-brand-color-hex)",
  [BRAND_COLORS.LIGHT]: "var(--light-brand-color-hex)"
}

export const TEXT_COLORS = {
  [APP_COLORS.WHITE]: scssVariables["color-ui--white"],
  [APP_COLORS.LIGHT_GREY]: scssVariables["color-ui--grey-20"],
  [APP_COLORS.GREY]: scssVariables["color-ui--grey-70"],
  [APP_COLORS.BLACK]: scssVariables["color-ui--grey-90"],
  [BRAND_COLORS.PRIMARY]: "var(--primary-brand-color-hex)",
  [BRAND_COLORS.SECONDARY]: "var(--secondary-brand-color-hex)",
  [BRAND_COLORS.DARK]: "var(--dark-brand-color-hex)",
  [BRAND_COLORS.LIGHT]: "var(--light-brand-color-hex)"
}

export const BLOCK_ACTIONS = {
  OPEN_URL: "open_url",
  OPEN_PAGE: "open_page",
  OPEN_EMAIL: "open_email",
  OPEN_NEXT: "open_next",
  TRIGGER_PURCHASE: "trigger_purchase",
  TRIGGER_FORM: "trigger_form",
  TRIGGER_OVERLAY: "trigger_overlay",
  SHARE: "share",
  OPEN_LIGHTBOX: "open_lightbox"
}

export const LIVESTREAM_PROVIDERS = {
  YOUTUBE: "youtube",
  ZOOM: "zoom",
  VIMEO: "vimeo",
  FACEBOOK: "facebook"
}

export const DYNAMIC_ORDER_BY = ["created_desc", "created_asc", "alphabetical"]

export const isBlockActionLink = action =>
  [BLOCK_ACTIONS.OPEN_URL, BLOCK_ACTIONS.OPEN_PAGE].includes(action)

export const triggerBlockAction = (
  blockAction,
  hasPurchased = false,
  disableClose = false
) => {
  const {
    action,
    overlay_id,
    form_id,
    products,
    secondary_action
  } = blockAction
  const { TRIGGER_FORM, TRIGGER_OVERLAY, TRIGGER_PURCHASE } = BLOCK_ACTIONS

  switch (action) {
    case TRIGGER_FORM:
      form_id &&
        EventBus.$emit(EVENTS.TRIGGER_FORM, {
          formId: form_id,
          continueAction: secondary_action,
          disableClose
        })
      break
    case TRIGGER_OVERLAY:
      overlay_id && EventBus.$emit(EVENTS.TRIGGER_OVERLAY, overlay_id)
      break
    case TRIGGER_PURCHASE:
      if (hasPurchased) {
        secondary_action &&
          EventBus.$emit(EVENTS.TRIGGER_BLOCK_ACTION, secondary_action)
      } else {
        const productIds = products.map(product => product.id)

        productIds &&
          EventBus.$emit(EVENTS.TRIGGER_CHECKOUT, {
            productIds: productIds
          })
      }
      break
  }
}

export const buildBlockMountedEvent = (getBlock, element) => ({
  getBlock,
  element
})

export const getBlockImage = (block, pageAttributes = {}) => {
  const attribute =
    pageAttributes[block.data[BLOCK_PAGE_ATTRIBUTES.IMAGE_PAGE_ATTRIBUTE]]

  return isPresent(attribute) && attribute.type === TYPES.IMAGE
    ? attribute.value
    : isPresent(block.data.image)
    ? block.data.image
    : null
}

export const getBlockVideo = (block, pageAttributes = {}) => {
  const attribute =
    pageAttributes[block.data[BLOCK_PAGE_ATTRIBUTES.VIDEO_PAGE_ATTRIBUTE]]

  return isPresent(attribute) && attribute.type === TYPES.VIDEO
    ? attribute.value
    : isPresent(block.data.video)
    ? block.data.video
    : null
}

export const getBlockAudio = (block, pageAttributes = {}) => {
  const attribute =
    pageAttributes[block.data[BLOCK_PAGE_ATTRIBUTES.AUDIO_PAGE_ATTRIBUTE]]

  return isPresent(attribute) && attribute.type === TYPES.AUDIO
    ? attribute.value
    : isPresent(block.data.audio)
    ? block.data.audio
    : null
}

export const getBlockItems = (block, references = {}) => {
  const items = isPresent(block.data.collection)
    ? block.data.collection?.items || []
    : []

  return isPresent(references)
    ? items.map(item => ({
        ...item,
        data: {
          ...item.data,
          ...(references[item.data.id] || {})
        }
      }))
    : items
}

export const decodeBlockAction = data => JSON.parse(atob(data))

export { BLOCK_TYPES }
