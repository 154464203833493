import isStandalone from "@/lib/is-platform/is-standalone"
import isAppStore from "@/lib/is-platform/is-app-store"
import isBuilderContext from "@/lib/is-platform/is-builder-context"
import isPreviewContext from "@/lib/is-platform/is-preview-context"
import isSupportedDevice from "@/lib/is-platform/is-supported-device"
import isIos from "@/lib/is-platform/is-ios"

const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
const isCypress = () => !!window.Cypress

const isIpad = () =>
  /Macintosh/i.test(navigator.userAgent) &&
  navigator.maxTouchPoints &&
  navigator.maxTouchPoints > 1

const isNativePush = isAppStore()

const isMobile = () => window.matchMedia("(max-width: 768px)").matches

export {
  isStandalone,
  isAppStore,
  isBuilderContext,
  isPreviewContext,
  isSafari,
  isIos,
  isIpad,
  isCypress,
  isNativePush,
  isSupportedDevice,
  isMobile
}
