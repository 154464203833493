<script>
import { isPresent, getImageUrl } from "@/lib/utils"

export const DEFAULT_STYLES = { height: "50%" }

export default {
  functional: true,

  props: {
    logo: { type: Object, default: () => ({}) },
    fallbackText: { type: String, default: "" },
    styles: { type: Object, default: () => DEFAULT_STYLES }
  },

  render(h, { props }) {
    return isPresent(props.logo) ? (
      <img
        class="v-toolbar-logo"
        style={`height: ${props.styles.height}`}
        src={getImageUrl(props.logo)}
      />
    ) : (
      <div class="v-toolbar__text">{props.fallbackText}</div>
    )
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";

.v-toolbar-logo {
  max-height: 100%;
  max-width: 100%;
  align-self: center;
  max-height: $toolbar--height;
}
</style>
