import Vue from "vue"

import ApiClient from "@/lib/api-client"
import { omit } from "@/lib/utils"

export default {
  namespaced: true,

  state: {
    subscriptions: {}
  },

  actions: {
    subscribe({ commit }, programId) {
      return ApiClient.post(`/programs/${programId}/subscribe`).then(res => {
        commit("addProgramSubscription", {
          programId,
          subscription: res.data.program_subscription
        })
        return res
      })
    },

    fetchSubscriptions({ commit }) {
      return ApiClient.get("/programs/subscriptions").then(res => {
        commit("setProgramSubscriptions", res.data.program_subscriptions || {})
      })
    },

    completeStage({ commit }, { programId, stageId }) {
      return ApiClient.post(
        `/programs/${programId}/stages/${stageId}/complete`
      ).then(res => {
        commit("addCompletedStage", { programId, stageId })
        return res
      })
    },

    uncompleteStage({ commit }, { programId, stageId }) {
      return ApiClient.delete(`/programs/${programId}/stages/${stageId}`).then(
        res => {
          commit("removeCompletedStage", { programId, stageId })
          return res
        }
      )
    },

    complete(_, programId) {
      return ApiClient.post(`/programs/${programId}/complete`)
    },

    fetchPrograms() {
      return ApiClient.get(`/programs`).then(res => res.data.programs)
    },

    fetchProgramAccess(_, programId) {
      return ApiClient.get(`/programs/${programId}/access`)
    }
  },

  mutations: {
    setProgramSubscriptions(state, subscriptions) {
      state.subscriptions = subscriptions
    },

    addProgramSubscription(state, { programId, subscription }) {
      Vue.set(state.subscriptions, programId, subscription)
    },

    addCompletedStage(state, { programId, stageId }) {
      const programStages = state.subscriptions[programId]
      if (!programStages) throw "Program Not Found"

      Vue.set(state.subscriptions[programId], stageId, true)
    },

    removeCompletedStage(state, { programId, stageId }) {
      const programStages = state.subscriptions[programId]
      if (!programStages) throw "Program Not Found"

      state.subscriptions[programId] = omit(programStages.completed_stages, [
        stageId
      ])
    }
  },

  getters: {
    userHasProgramSubscriptions(state) {
      return Object.keys(state.subscriptions).length > 0
    },

    isUserSubscribedToProgram(state, getters, __, rootGetters) {
      return programId => {
        if (rootGetters["auth/isAuthenticated"]) {
          return !!state.subscriptions[programId]
        }

        return false
      }
    },

    completedStagesForProgram(state, getters, _, rootGetters) {
      return programId => {
        if (
          rootGetters["auth/isAuthenticated"] &&
          programId &&
          getters.isUserSubscribedToProgram(programId)
        ) {
          return state.subscriptions[programId]
        }

        return {}
      }
    },

    activeStageForProgram(state, getters, _, rootGetters) {
      return (programId, stages) => {
        if (
          rootGetters["auth/isAuthenticated"] &&
          getters.isUserSubscribedToProgram(programId)
        ) {
          const completed_stages = state.subscriptions[programId]

          const uncompleted_stage = stages.find(
            stage => !completed_stages[stage.id]
          )

          return uncompleted_stage
            ? uncompleted_stage
            : stages[stages.length - 1]
        }

        return null
      }
    }
  }
}
