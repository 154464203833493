<template>
  <portal selector="#modal-portal">
    <transition name="fade">
      <div
        v-if="isOpen"
        @click="closeModal"
        class="p-fixed v-modal__backdrop"
        :style="{ zIndex: 150 + zIndex }"
      ></div>
    </transition>
    <transition :name="transitionModalName">
      <div
        v-if="isOpen"
        :class="[
          {
            '--short': short,
            '--x-short': xShort,
            '--video-fullscreen': isFullscreen,
            '--w-overlay': !!$slots.overlay,
            '--vertical': vertical
          },
          modalClass,
          'p-fixed v-modal'
        ]"
        data-role="modal"
        data-cy="modal"
        @click.self="closeModal"
        :style="{ zIndex: 150 + zIndex + 1 }"
      >
        <div class="p-relative v-modal__content">
          <div
            v-if="!disableClose"
            data-cy="close-modal"
            class="p-absolute v-modal__close"
          >
            <v-button round outlined secondary @click="closeModal">
              <v-icon size="24px" icon="close" color="white" />
            </v-button>
          </div>

          <div
            v-if="$slots.overlay"
            class="v-modal__overlay container"
            :style="{
              '--app-height': `calc(${appHeight.height}px - ${pageContentHeight}px + 12px)`
            }"
            data-cy="modal-overlay"
          >
            <slot name="overlay"></slot>
          </div>

          <div
            class="v-modal__content-wrapper"
            data-role="modal-content-wrapper"
          >
            <div class="v-modal__before container">
              <slot name="before" />
            </div>

            <transition name="fade-out-left" mode="out-in">
              <div :key="activeHash" class="v-modal__page container">
                <slot :name="activeHash"></slot>
              </div>
            </transition>

            <slot name="after"></slot>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import { mapState } from "vuex"

import { appHeight } from "@/lib/app-height-watcher"
import { toHashList } from "@/lib/url-helpers"

export default {
  props: {
    vertical: { type: Boolean, default: false },
    short: { type: Boolean, default: false },
    xShort: { type: Boolean, default: false },
    disableClose: { type: Boolean, default: false },
    modalClass: { type: String, default: "" }
  },

  data() {
    return {
      isOpen: false,
      pageContentHeight: 0,
      modalHashes: {},
      activeHash: "",
      appHeight,
      zIndex: 1
    }
  },

  mounted() {
    Object.keys(this.$slots).forEach(h => {
      if (h.startsWith("#")) {
        this.modalHashes[h.substring(1)] = true
      }
    })
  },

  computed: {
    ...mapState("videos", ["isFullscreen"]),

    transitionModalName() {
      return this.vertical ? "fade-from-left-modal" : "fade-in-up-modal"
    }
  },

  methods: {
    closeModal() {
      if (!this.disableClose) {
        const hashList = toHashList(this.$route.hash)

        if (hashList.some(h => this.modalHashes[h])) {
          this.$router.removeHash()
        }
        this.$emit("close")
      }
    }
  },

  watch: {
    isFullscreen() {
      if (this.isFullscreen) {
        const modal = document.querySelector("[data-role=modal]")
        if (modal) modal.scrollTop = 0
      }
    },

    isOpen() {
      if (this.isOpen) {
        setTimeout(() => {
          const contentWrapper = document.querySelector(
            "[data-role=modal-content-wrapper]"
          )

          if (contentWrapper) {
            this.pageContentHeight = contentWrapper.getBoundingClientRect().height
          }
        }, 401) // Animation time + 1ms
      }
    },

    "$route.hash": {
      immediate: true,
      handler: function(hashes, lastHash) {
        this.$nextTick(() => {
          const hashesList = toHashList(hashes)

          const index = hashesList.findIndex(v => this.modalHashes[v])
          const hashValue = hashesList[index]

          if (hashValue) {
            const hash = `#${hashValue}`
            this.isOpen = true
            this.activeHash = hash
            this.$emit("open", hash)
            this.zIndex = 2 * index

            if (!lastHash) this.$emit("initialOpen", hash)
          } else {
            this.isOpen = false
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

$overlay--full-height: calc(var(--app-height) - 1vh - env(safe-area-inset-top));

$modal--x-short-height: 300px;
$modal--short-height: 475px;

.v-modal {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: $modal--z-index;

  &.--short {
    .v-modal__content {
      top: 40vh; // Fallback
      top: calc(100vh - #{$modal--short-height});
    }
  }

  &.--vertical {
    @include hide-scroll-bars;

    .v-modal__content {
      width: 400px;
      max-width: 80vw;
      top: 0;
      padding-bottom: 0;
      border-radius: 0;
    }

    .v-modal__close {
      padding-top: env(safe-area-inset-top);
      top: $size--4;
      right: -$size--10;
    }
  }

  &.--x-short {
    .v-modal__content {
      top: 60vh; // Fallback
      top: calc(100vh - #{$modal--x-short-height});
    }
  }

  &.--video-fullscreen {
    overflow: hidden;

    .v-modal__content {
      top: 0;
      height: 100%;
      padding-bottom: 0;
    }

    .v-modal__backdrop {
      height: 100%;
    }
  }

  &.--w-overlay {
    .v-modal__content {
      top: calc(1vh + env(safe-area-inset-top));
      padding-bottom: 0;
      overflow: hidden;
      border-top-left-radius: $modal--border-radius;
      border-top-right-radius: $modal--border-radius;
      min-height: $overlay--full-height;
      max-height: $overlay--full-height;
    }

    .v-modal__content-wrapper {
      padding-top: $size--4;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $color-ui--white;
      border-top-left-radius: $modal--border-radius;
      border-top-right-radius: $modal--border-radius;
      box-shadow: $box-shadow--n-medium;
      z-index: 11;
    }

    .v-modal__close {
      top: $size--4;

      .v-button {
        border-color: rgba($color-rgb--white, 0.24);
        background: rgba($color-rgb--black, 0.24);
      }
    }

    .v-modal__overlay {
      @include hide-scroll-bars;

      max-height: $overlay--full-height;
      overflow-y: scroll;
    }
  }
}

.v-modal__backdrop {
  background: rgba(black, 0.6);
  width: 100%;
  height: 110%;
  top: -10%;
  left: 0;
  z-index: $modal--z-index - 1;
}

.v-modal__content {
  z-index: 5;
  min-height: 100vh;
  top: $modal--offset-top;
  top: calc(#{$modal--offset-top} + env(safe-area-inset-top));
  padding-bottom: 40vh;
  border-top-left-radius: $modal--border-radius;
  border-top-right-radius: $modal--border-radius;
  background: $color-ui--white;
  transform: translate3d(0, 0, 0);
}

.v-modal__content-wrapper {
  padding-bottom: calc(#{$size--4} + env(safe-area-inset-bottom));
}

.v-modal__close {
  top: -$size--10;
  right: $size--4;
  opacity: 0;
  animation: fade-in-up 0.3s ease forwards;
  animation-delay: 0.5s;
  z-index: 100;
}
</style>
