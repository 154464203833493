<template>
  <div
    class="blocks-container"
    :data-role="ROLES.BLOCK_CONTAINER"
    :data-parent="parentBlock.uuid"
    :data-parent-type="parentBlock.type"
  >
    <block
      v-for="(block, i) in blocksToRender"
      :block="block"
      :page-id="pageId"
      :parent-block="parentBlock"
      :index="i"
      :key="block.uuid"
      :unlock="unlock"
      :ignore-blocks="ignoreBlocks"
    />
  </div>
</template>

<script>
import { ROLES } from "@/lib/stream-utils"

import Block from "@/components/stream/Block"

export default {
  components: {
    Block
  },

  props: {
    blocks: { type: Array, required: true },
    parentBlock: { type: Object, default: () => ({}) },
    unlock: { type: Boolean, default: null },
    pageId: { type: [String, Number], default: null },
    limit: { type: [String, Number], default: null },
    ignoreBlocks: { type: Array, default: () => [] }
  },

  data() {
    return {
      ROLES
    }
  },

  computed: {
    blocksToRender() {
      return (this.limit && parseInt(this.limit)
        ? this.blocks.slice(0, parseInt(this.limit))
        : this.blocks
      ).filter(block => !this.ignoreBlocks.includes(block.type))
    }
  },

  mounted() {
    this.$emit("init")
  }
}
</script>
