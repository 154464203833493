<template>
  <div class="v-switch">
    <v-ons-checkbox
      :input-id="`switch-${_uid}`"
      type="checkbox"
      class="v-switch__input"
      :checked="value"
      v-model="checked"
      @change="$emit('input', $event.target.checked)"
    />
    <label class="v-switch__label" :for="`switch-${_uid}`">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false }
  },

  data() {
    return {
      checked: this.value
    }
  },

  watch: {
    value(checked) {
      this.checked = checked
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";

.v-switch {
  .v-switch__label {
    margin-left: $size--2;
    cursor: pointer;
  }

  ons-checkbox {
    .checkbox__input {
      &:before {
        display: none;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .checkbox--material__checkmark {
      width: $size--6;
      height: $size--6;
    }

    .checkbox__checkmark {
      &:before,
      &:after {
        transition: all 0.08s ease-in;
      }

      &:before {
        border-color: $color-ui--grey-20;
        background-color: $color-ui--white;
        height: $size--6;
        width: $size--6;
        border-radius: 5px;
      }

      &:after {
        top: 7px;
        left: 6px;
        width: 11px;
        border-color: $color-ui--grey-60;
      }
    }

    :checked + .checkbox__checkmark {
      &:before {
        border: 2px solid var(--primary-brand-color-hex);
        background: var(--primary-brand-color-hex);
      }

      &:after {
        border-color: $color-ui--white;
      }
    }

    &:focus + .checkbox__checkmark:before {
      border-color: rgba(var(--primary-brand-color-rgb-vals), 0.75);
    }

    &:disabled {
      background-color: rgba(black, 0.1);
      opacity: 0.6;
      box-shadow: none;
    }
  }
}
</style>
