<template>
  <div class="v-input__container">
    <div class="v-input__wrapper d-flex">
      <input
        ref="input"
        :class="[
          { '--flush': flush, '--has-append': !!$slots.append },
          inputClass,
          'v-input'
        ]"
        :value="value"
        v-bind="$attrs"
        :disabled="disabled"
        @input="handleInput"
      />

      <div v-if="!!$slots.append" class="v-input__append">
        <slot name="append" />
      </div>
    </div>

    <small v-if="hasError" class="invalid-feedback">
      <template v-if="invalidMessage">
        {{ invalidMessage }}
      </template>

      <template v-if="validator.$params.required && !validator.required">
        {{ $t("components.forms.vInput.errorFieldRequire") }}
      </template>

      <template v-if="validator.$params.minLength && !validator.minLength">
        {{
          $t("components.forms.vInput.errorFieldMin", {
            minLength: validator.$params.minLength.min
          })
        }}
      </template>

      <template v-if="validator.$params.maxLength && !validator.maxLength">
        {{
          $t("components.forms.vInput.errorFieldMax", {
            maxLength: validator.$params.maxLength.max
          })
        }}
      </template>

      <template v-if="validator.$params.email && !validator.email">
        {{ $t("components.forms.vInput.errorFieldEmail") }}
      </template>

      <template v-if="validator.$params.between && !validator.between">
        {{
          $t("components.forms.vInput.errorFieldMustBeBetween", {
            betweenMin: validator.$params.between.min,
            betweenMax: validator.$params.between.max
          })
        }}
      </template>

      <template v-if="validator.$params.url && !validator.url">
        {{ $t("components.forms.vInput.errorFieldUrl") }}
      </template>

      <template v-if="validator.$params.startsWith && !validator.startsWith">
        {{
          $t("components.forms.vInput.errorMustStart", {
            startsWith: validator.$params.startsWith.value
          })
        }}
      </template>
    </small>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: { type: String, default: "" },
    flush: { type: Boolean, default: false },
    validator: { type: Object, default: () => ({}) },
    validateOnInit: { type: Boolean, default: false },
    invalidMessage: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    inputClass: { type: String, default: null },
    autofocus: { type: Boolean, default: false }
  },

  computed: {
    hasError() {
      return (
        this.invalidMessage ||
        (this.validator &&
          (this.validator.$error ||
            (this.validateOnInit &&
              !this.validator.$dirty &&
              this.validator.$invalid)))
      )
    }
  },

  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.focus()
      })
    }
  },

  methods: {
    handleInput(event) {
      this.$emit("input", event.target.value)
      this.$emit("inputEvent", event)
    },

    focus() {
      this.$refs.input && this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/variables";

.v-input {
  appearance: none;
  position: relative;
  width: 100%;
  height: $input--base-height;
  padding: $size--2 $size--4;
  color: $color-ui--grey-80;
  font-size: $font-size--base;
  line-height: 1.5;
  border: 2px solid $color-ui--grey-20;
  border-radius: var(--input-border-radius);
  transition: all 0.08s ease-in;
  background: $color-ui--white;
  box-shadow: 0 0 0 0 rgba(var(--primary-brand-color-rgb-vals), 0.16);

  &:focus {
    border: 2px solid rgba(var(--primary-brand-color-rgb-vals), 0.75);
    box-shadow: 0 0 0 4px rgba(var(--primary-brand-color-rgb-vals), 0.16);
  }

  &:disabled {
    background: rgba(black, 0.1);
    opacity: 0.6;
    box-shadow: none;
  }

  &.--flush {
    border: none;
    border-radius: 0px;
    padding: $size--2 0;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  &.--has-append {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.v-input__append {
  border-top-right-radius: var(--input-border-radius);
  border-bottom-right-radius: var(--input-border-radius);
  border: 2px solid $color-ui--grey-20;
  border-left: none;
  padding: $size--2 $size--3;
  color: $color-ui--grey-70;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight--semi-bold;
  min-width: $size--10;
  background-color: $color-ui--white;
  flex-shrink: 0;
}

.invalid-feedback {
  color: #ff0052;
  font-weight: 500;
}
</style>
