import axios from "axios"
// import NProgress from "nprogress"
import isStandalone from "@/lib/is-platform/is-standalone"
import isAppStore from "@/lib/is-platform/is-app-store"
import { clientWidth, clientHeight } from "@/lib/utils"

import store from "@/store"

class ApiClient {
  constructor() {
    this.baseUrl = process.env.VUE_APP_API_BASE_URL
  }

  fetch(path, options = {}) {
    // NProgress.start()

    const { method, params, data } = options

    const authHeaders = store.getters["auth/isAuthenticated"]
      ? {
          "x-user-email": store.getters["auth/currentUser"].email,
          "x-user-token": store.getters["auth/authenticationToken"]
        }
      : {}

    const headers = {
      "x-account-app": store.getters.app?.slug,
      "x-session-id": store.state.analytics.sessionId,
      "x-device-id": store.state.analytics.deviceId,
      "x-origin-href": window.location.href,
      "x-standalone": isStandalone(),
      "x-native-app": isAppStore(),
      "x-client-height": clientHeight(),
      "x-client-width": clientWidth(),
      Accept: "application/json",
      ...authHeaders,
      ...options.headers
    }

    const apiClient = axios.create({ baseURL: this.baseUrl })
    apiClient.interceptors.response.use(res => {
      // NProgress.done()
      return res
    })

    return apiClient.request({
      url: path,
      responseType: "json",
      method,
      options,
      headers,
      params,
      data
    })
  }

  get(path, options = {}) {
    return this.fetch(path, { method: "GET", ...options })
  }

  post(path, params, options = {}) {
    return this.fetch(path, { method: "POST", data: params, ...options })
  }

  put(path, params, options = {}) {
    return this.fetch(path, { method: "PUT", data: params, ...options })
  }

  delete(path, options = {}) {
    return this.fetch(path, { method: "DELETE", ...options })
  }
}

export default new ApiClient()
