import ApiClient from "@/lib/api-client"

export default {
  namespaced: true,

  state: {
    purchasedProducts: [],
    programs: [],
    events: [],
    recentPages: [],
    packs: [],
    pages: [],
    sharedItems: [],
    upcomingPages: [],
    bookmarkedItems: [],
    hasFormSubmissions: false
  },

  mutations: {
    setContent(
      state,
      {
        purchased_products,
        programs,
        recentPages,
        events,
        packs,
        pages,
        shared_items,
        upcoming_pages,
        bookmarked_items,
        has_form_submissions
      }
    ) {
      if (purchased_products) state.purchasedProducts = purchased_products
      if (programs) state.programs = programs
      if (recentPages) state.recentPages = recentPages
      if (events) state.events = events
      if (packs) state.packs = packs
      if (pages) state.pages = pages
      if (shared_items) state.sharedItems = shared_items
      if (upcoming_pages) state.upcomingPages = upcoming_pages
      if (bookmarked_items) state.bookmarkedItems = bookmarked_items
      state.hasFormSubmissions = has_form_submissions
    }
  },

  actions: {
    fetchMyContent({ commit }) {
      return ApiClient.get("/my-content").then(res => {
        commit("setContent", res.data)
      })
    }
  }
}
