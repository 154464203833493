export default {
  namespaced: true,

  state: {
    globalPlayer: null,
    isNativePlayer: false
  },

  mutations: {
    setGlobalPlayer(state, player) {
      state.globalPlayer = player
    },

    setIsNativePlayer(state, isNativePlayer) {
      state.isNativePlayer = isNativePlayer
    }
  },

  actions: {
    setupGlobalPlayer({ state, commit }) {
      if (state.globalPlayer) return

      const AudioPlayer = require("@/lib/audio/audio-player").default

      commit(
        "setGlobalPlayer",
        new AudioPlayer({ native: state.isNativePlayer })
      )
    }
  },

  getters: {
    globalPlayer(state) {
      return state.globalPlayer
    }
  }
}
