import Hls from "hls.js"

const MAX_RETRIES = 15

export const configureHls = (source, media, fatalErrorCallback = () => {}) => {
  // TODO: test
  const hls = new Hls({
    maxMaxBufferLength: 20 * 60 // default 600s
  })

  hls.loadSource(source)
  hls.attachMedia(media)

  let networkRetryCounter = 0
  let mediaRetryCounter = 0
  let networkRetryTimer = null
  let mediaRetryTimer = null

  hls.on(Hls.Events.FRAG_LOADED, () => {
    networkRetryCounter = 0
    mediaRetryCounter = 0
  })

  hls.on(Hls.Events.ERROR, (event, data) => {
    if (data.fatal) {
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          clearTimeout(networkRetryTimer)

          networkRetryTimer = setTimeout(() => {
            console.log("[Hls] Network error encountered...")

            if (networkRetryCounter >= MAX_RETRIES) {
              console.error(
                "[Hls] Maximum retries reached. Failing playback..."
              )
              fatalErrorCallback()
              return
            }

            console.log("[Hls] Attempting to recover...")

            if (data.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR) {
              hls.loadSource(source)
            } else {
              hls.startLoad()
            }

            networkRetryCounter++
          }, 1000)
          break
        case Hls.ErrorTypes.MEDIA_ERROR:
          clearTimeout(mediaRetryTimer)
          mediaRetryTimer = setTimeout(() => {
            console.log("[Hls] Media error encountered...")

            if (mediaRetryCounter >= MAX_RETRIES) {
              console.error(
                "[Hls] Maximum retries reached. Failing playback..."
              )
              fatalErrorCallback()
              return
            }

            console.log(
              "[Hls] Media error encountered... Attempting to recover..."
            )

            hls.recoverMediaError()

            mediaRetryCounter++
          }, 1000)
          break
        default:
          console.error("[Hls] Unknown error encountered.", event, data)
          break
      }
    }
  })

  return {
    destroy: () => {
      clearTimeout(networkRetryTimer)
      clearTimeout(mediaRetryTimer)
      hls.destroy()
    }
  }
}

export const isHlsSupported = () => Hls.isSupported()
