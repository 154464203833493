import ApiClient from "@/lib/api-client"

export default {
  namespaced: true,

  state: {
    overlays: {}
  },

  actions: {
    fetchOverlay({ commit }, overlayId) {
      return ApiClient.get(`/overlays/${overlayId}`).then(res => {
        const overlay = res.data.overlay

        commit("setOverlay", overlay)

        return overlay
      })
    }
  },

  mutations: {
    setOverlay(state, overlay) {
      if (!overlay.id) {
        throw new Error("Overlay id is missing")
      }

      state.overlays[overlay.id] = overlay
    }
  },

  getters: {
    getOverlay(state) {
      return overlayId => state.overlays[overlayId]
    }
  }
}
