<script>
export default {
  functional: true,

  props: {
    icon: { type: String, required: true },
    size: { type: String, default: "" },
    outlined: { type: Boolean, default: false },
    color: { type: String, default: "" }
  },

  render(h, { props, data }) {
    const attrs = data.attrs || {}

    return (
      <span
        class={[data.class, data.staticClass, `material-icons-round v-icon`]}
        style={`${props.size.length && `font-size: ${props.size}`}; ${props
          .color.length && `color: ${props.color};`}`}
        {...{ attrs }}
      >
        {props.icon}
      </span>
    )
  }
}
</script>

<style lang="scss">
.v-icon {
  font-size: inherit;
}
</style>
